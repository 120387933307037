import { FormControl, MenuItem, Select, SelectChangeEvent, Stack } from '@mui/material';
import { Title } from '../../../../../../../../../../components';
import { ErrorKeys } from '../../../../../../../../../../constants';
import { useErrorContext } from '../../../../../../../../../../context';
import { useMealActions } from '../../../../../../../../../../store/meal-store';
import { ModeType } from '../../../../../../../../../types';
import { PresetValues, useGetBurners } from '../../../meals/hooks';

interface Props {
  mealId: string;
  stepId: string;
  mode?: ModeType;
  disabled?: boolean;
  modes: PresetValues<ModeType>;
}

const PresetMode: React.FC<Props> = ({ mealId, disabled, stepId, mode, modes }) => {
  const { updateMode, updateBurners } = useMealActions();
  const { burners } = useGetBurners();
  const { error } = useErrorContext();

  const handleUpdateMode = (e: SelectChangeEvent<string>) => {
    const newMode = (modes.data || []).find((m) => m.id === e.target.value);

    if (!newMode) return;

    if (newMode.name === 'Indirect') {
      if (!burners) return;
      const bottomBurner = burners.find((b) => b.name === 'Chamber');
      if (!bottomBurner) return;

      updateBurners(mealId, stepId, [bottomBurner], 'burners');
      updateMode(mealId, stepId, newMode);
      return;
    }

    if (newMode.name === 'Oven') {
      if (!burners) return;
      const ovenBurner = burners.filter((b) => b.name === 'Bottom Heat' || b.name === 'Chamber');

      if (!ovenBurner) return;

      updateBurners(mealId, stepId, ovenBurner, 'burners');
      updateMode(mealId, stepId, newMode);
      return;
    }

    updateBurners(
      mealId,
      stepId,
      burners?.filter((b) => b.name !== 'Bottom Heat') || [],
      'burners',
    );
    updateMode(mealId, stepId, newMode);
  };

  return (
    <Stack direction='column' gap={1} sx={{ width: '100%' }}>
      <Title title='Grill Mode' />
      <FormControl fullWidth>
        <Select
          size='small'
          defaultValue=''
          disabled={disabled}
          value={mode?.id ?? ''}
          onChange={handleUpdateMode}
          labelId='burner-mode'
          id='burner-mode'
          data-testid='preset-mode'
          error={!!error.get(`${stepId}-${ErrorKeys.MODE}`)}
        >
          {modes.data?.map((burnerMode) => (
            <MenuItem key={burnerMode.id} value={burnerMode.id} data-testid='preset-mode-option'>
              {burnerMode.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Stack>
  );
};
export default PresetMode;
