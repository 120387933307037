import AccessTimeIcon from '@mui/icons-material/AccessTime';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import { Box, Chip, Paper, Stack, Typography } from '@mui/material';
import { useInfiniteQuery } from '@tanstack/react-query';
import React, { useCallback, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { useNavigate } from 'react-router-dom';
import { NavigationPaths, QueryKeys } from '../../constants';
import { RecipeTooltip } from '../../modules/ingredients';
import { recipeService } from '../../modules/recipes';

type Props = {
  recipeIds: string[];
};

const AssociatedReciopes: React.FC<Props> = ({ recipeIds }) => {
  const navigate = useNavigate();
  const { ref, inView } = useInView({ threshold: 0 });
  const { data, error, fetchNextPage, isLoading, isFetching } = useInfiniteQuery({
    queryKey: [QueryKeys.associatedRecipes, recipeIds],
    queryFn: async ({ pageParam }) => {
      const result = await recipeService.getRecipesByIds({
        recipeIds,
        pageParams: pageParam || 1,
      });
      return result;
    },
    getNextPageParam: (metadata) => {
      if (metadata.meta.currentPage === metadata.meta.lastPage) {
        return undefined;
      }

      return metadata.meta.currentPage + 1;
    },
    enabled: recipeIds.length > 0,
  });

  const handleNavigate = useCallback(
    (id: string) => {
      navigate(`${NavigationPaths.DETAILS}?recipeId=${id}`);
    },
    [navigate],
  );

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [fetchNextPage, inView]);

  if (isFetching) return <div>Loading...</div>;

  if (error) return <div>Error while fethcing associated recipes</div>;

  return (
    <Paper sx={{ minWidth: '500px', p: 3, overflow: 'auto' }}>
      <Stack direction='column' gap={3} width='100%'>
        <Typography>Associated Recipes</Typography>

        {data?.pages.map((page) =>
          page.data.map((recipe, idx) => (
            <Stack
              key={recipe.id}
              direction='row'
              justifyContent='flex-start'
              gap={2}
              alignItems='center'
            >
              <RecipeTooltip
                displayValue={
                  <OpenInNewOutlinedIcon
                    className='icon'
                    sx={{ color: 'kqn.cooper', cursor: 'pointer' }}
                    onClick={() => handleNavigate(recipe.id)}
                  />
                }
              >
                <Box ref={idx === page.data.length - 1 ? ref : null} key={recipe.id}>
                  <Stack gap={2} justifyContent='space-between' direction='row'>
                    <Stack
                      direction='row'
                      width='100%'
                      gap={2}
                      sx={{
                        bgcolor: 'kqn.white',
                        p: 1,
                        pr: 2,
                        boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.4)',
                        borderRadius: '8px',
                        '&:hover': { cursor: 'pointer' },
                      }}
                      onClick={() => handleNavigate(recipe.id)}
                    >
                      <img
                        src={recipe.imageUri}
                        alt={recipe.name}
                        style={{
                          width: '80px',
                          height: '80px',
                          borderRadius: '8px',
                          objectFit: 'cover',
                        }}
                      />
                      <Stack direction='column' justifyContent='space-between' width='100%'>
                        <Typography variant='body2'>{recipe.name}</Typography>
                        <Stack
                          direction='row'
                          alignItems='center'
                          justifyContent='space-between'
                          gap={2}
                          sx={{ color: 'kqn.darkGray' }}
                        >
                          <Stack direction='row' alignItems='center' gap={0.5}>
                            <AccessTimeIcon />
                            <Typography variant='body2'>{recipe.cookingTime}min</Typography>
                          </Stack>
                          <Typography variant='body2' align='right'>
                            {recipe.complexity.name}
                          </Typography>
                        </Stack>
                      </Stack>
                    </Stack>
                    <Stack
                      direction='row'
                      alignItems='center'
                      justifyContent='flex-start'
                      gap={2}
                      sx={{ flexBasis: '30%' }}
                    >
                      {recipe.categories?.map((category) => {
                        return (
                          <Chip
                            key={category.id}
                            label={category.name}
                            sx={{
                              color: 'kqn.darkerGray',
                              borderRadius: '8px',
                              bgcolor: 'kqn.lightGray',
                            }}
                          />
                        );
                      })}
                    </Stack>
                  </Stack>
                </Box>
              </RecipeTooltip>
              <Typography>{recipe.name}</Typography>
            </Stack>
          )),
        )}
      </Stack>
    </Paper>
  );
};

export default AssociatedReciopes;
