import { Checkbox, TableBody, TableCell, TableRow } from '@mui/material';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { TableCellStyled } from '../../../../components';
import { getComparator, Order, stableSort } from '../../../../shared';
import { PaginatedResult } from '../../../../types';
import { PageableUnit, UnitHeadCells } from '../../../types';

type Props = {
  data: PaginatedResult<PageableUnit>;
  selected: string[];
  page: number;
  perPage: number;
  order: Order;
  orderBy: keyof UnitHeadCells;
  handleOnSelect: (event: React.MouseEvent<unknown, MouseEvent>, name: string) => void;
};

const UnitTableBody: React.FC<Props> = ({
  data,
  page,
  perPage,
  order,
  orderBy,
  handleOnSelect,
  selected,
}) => {
  const navigate = useNavigate();
  const { data: units } = data;
  const isSelected = (id: string) => selected.indexOf(id) !== -1;

  const emptyRows = page > 0 ? Math.max(0, (page - 1) * perPage - units.length) : 0;

  const rows = useMemo(
    () => stableSort(units, getComparator(order, orderBy)).slice(0, perPage),
    [units, order, orderBy, perPage],
  );

  const handleOnClick = (id: string) => {
    navigate(`/recipes/units/${id}`);
  };

  return (
    <TableBody>
      {rows.map((row, index) => {
        const isItemSelected = isSelected(row.id);
        const labelId = `enhanced-table-checkbox-${index}`;

        return (
          <TableRow
            hover
            onClick={(event) => handleOnSelect(event, row.id)}
            role='checkbox'
            aria-checked={isItemSelected}
            tabIndex={-1}
            key={row.id}
            selected={isItemSelected}
            sx={{ cursor: 'pointer' }}
          >
            <TableCellStyled padding='checkbox'>
              <Checkbox
                color='primary'
                checked={isItemSelected}
                inputProps={{ 'aria-labelledby': labelId }}
              />
            </TableCellStyled>
            <TableCellStyled
              component='th'
              id={labelId}
              scope='row'
              onClick={() => handleOnClick(row.id)}
            >
              {row.name}
            </TableCellStyled>
            <TableCellStyled align='center' onClick={() => handleOnClick(row.id)}>
              {row.nameDe}
            </TableCellStyled>
            <TableCellStyled align='center' onClick={() => handleOnClick(row.id)}>
              {row.namePlural}
            </TableCellStyled>
            <TableCellStyled align='center' onClick={() => handleOnClick(row.id)}>
              {row.nameDePlural}
            </TableCellStyled>
          </TableRow>
        );
      })}
      {emptyRows > 0 && (
        <TableRow style={{ height: 53 * emptyRows }}>
          <TableCell colSpan={6} />
        </TableRow>
      )}
    </TableBody>
  );
};

export default UnitTableBody;
