import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LockIcon from '@mui/icons-material/Lock';
import MenuIcon from '@mui/icons-material/Menu';
import PersonIcon from '@mui/icons-material/Person';
import {
  Box,
  Button,
  Divider,
  Menu,
  MenuItem,
  Skeleton,
  Stack,
  Toolbar,
  Typography,
} from '@mui/material';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { NavigationPaths, Paths } from '../../constants';
import { AuthUser, useAuthentication } from '../../context';
import { queries } from '../../modules/queries';
import { KoqoonLogo } from '../../shared/assets';
import { ChangePasswordModal } from '../modal';
import SidebarUserProfileModal from './sidebar-user-profile';

export const drawerWidth = 240;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

interface Props {
  user?: AuthUser;
  handleDrawerOpen: () => void;
  open: boolean;
}

const SidebarAppBar: React.FC<Props> = ({ user, handleDrawerOpen, open }) => {
  const navigate = useNavigate();
  const { signOut } = useAuthentication();
  const [isChangePasswordOpen, setIsChangePasswordOpen] = useState(false);
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);

  const { data, isFetching } = useQuery({
    ...queries.users.details(user?.id),
    enabled: !!user?.id,
    staleTime: Infinity,
    cacheTime: Infinity,
    refetchOnWindowFocus: true,
  });

  const pageableUser: any = {
    ...data,
    role: data?.role?.name,
  };

  const handleOnProfileClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLogout = async () => {
    try {
      signOut({ redirectPath: Paths.LOGIN });
      setAnchorEl(null);
    } catch (err: any) {
      toast.error(err);
    }
  };

  return (
    <AppBar
      position='fixed'
      open={open}
      sx={{ color: 'kqn.darkerGray', bgcolor: 'kqn.white', width: '100%' }}
    >
      <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Stack direction='row' alignItems='center' justifyContent='center'>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            onClick={handleDrawerOpen}
            edge='start'
            sx={{ marginRight: 5, curor: 'pointer' }}
          >
            <MenuIcon />
          </IconButton>
          <KoqoonLogo
            onClick={() => navigate(NavigationPaths.DASHBOARD)}
            style={{ cursor: 'pointer' }}
          />
        </Stack>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Button
            variant='text'
            onClick={handleOnProfileClick}
            aria-controls={isMenuOpen ? 'action-menu' : undefined}
            aria-expanded={isMenuOpen ? 'true' : undefined}
            aria-haspopup='true'
            disableRipple
            data-testid='navbar-profile-btn'
            sx={{ padding: 0, color: 'kqn.cooper' }}
          >
            <Box
              display='flex'
              justifyContent='center'
              sx={{ borderRadius: '50%', bgcolor: 'kqn.white', p: 0.5 }}
            >
              <PersonIcon fontSize='large' />
            </Box>
          </Button>
          <Menu
            open={isMenuOpen}
            id='action-menu'
            anchorEl={anchorEl}
            MenuListProps={{
              'aria-labelledby': 'action-btn',
              sx: { py: 1, px: 2 },
            }}
            onClose={() => setAnchorEl(null)}
            sx={{
              '& .MuiMenu-paper': {
                minWidth: '200px',
                marginTop: '10px',
                borderRadius: '8px',
              },
            }}
          >
            <MenuItem>
              {!isFetching ? (
                <Stack>
                  <Typography variant='body1' align='left' sx={{ fontWeight: 'bold' }}>
                    {data?.firstName} {data?.lastName}
                  </Typography>
                  <Typography variant='body2' align='left'>
                    {data?.email}
                  </Typography>
                </Stack>
              ) : (
                <Stack gap={1} width='100%'>
                  <Skeleton variant='rounded' width='100%' sx={{ color: 'red' }} />
                  <Skeleton variant='rounded' width='60%' />
                </Stack>
              )}
            </MenuItem>
            <Divider />
            <MenuItem onClick={() => setIsProfileOpen(true)}>
              <AccountCircleIcon
                sx={{ fontSize: '24px', marginRight: '10px', color: 'kqn.darkGray' }}
              />
              {!isFetching ? 'Profile' : <Skeleton variant='rounded' width='60%' />}
            </MenuItem>
            <Divider />
            <MenuItem onClick={() => setIsChangePasswordOpen(true)}>
              <LockIcon sx={{ fontSize: '24px', marginRight: '10px', color: 'kqn.darkGray' }} />
              {!isFetching ? 'Change Password' : <Skeleton variant='rounded' width='100%' />}
            </MenuItem>
            <Divider />
            <MenuItem
              data-testid='navbar-logout-btn'
              onClick={handleLogout}
              sx={{ color: 'kqn.cooper' }}
            >
              LOG OUT
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>
      <SidebarUserProfileModal
        user={pageableUser}
        isOpen={isProfileOpen}
        onClose={() => setIsProfileOpen(false)}
      />
      <ChangePasswordModal
        isOpen={isChangePasswordOpen}
        onClose={() => setIsChangePasswordOpen(false)}
      />
    </AppBar>
  );
};

export default SidebarAppBar;
