import { Divider, Stack, Typography } from '@mui/material';
import { memo } from 'react';
import { FlowSteps } from './flow-steps';
import FlowTimestamp from './flow-timestamp';
import { useGetChartData } from './hooks';

const FlowChart: React.FC = () => {
  const { updatedMap, totalDuration } = useGetChartData();

  return (
    <Stack
      direction='column'
      sx={{ overflow: 'hidden', height: '520px', overflowY: 'auto', overflowX: 'hidden' }}
    >
      <Stack
        data-testid='flow-chart'
        direction='row'
        sx={{
          height: '520px',
          overflowY: 'auto',
          overflowX: 'hidden',
          mb: 3,
          transition: 'all 1s ease-in-out',
        }}
      >
        <Stack
          direction='column'
          justifyContent='space-between'
          gap={2}
          py={3}
          width='100%'
          sx={{ bgcolor: 'kqn.white' }}
        >
          {Array.from(updatedMap).map(([key, value]) => {
            const name = key.replaceAll(/-.*/g, '');
            return (
              <Stack
                key={key}
                direction='column'
                height='100%'
                gap={2}
                sx={{ width: 'fit-content' }}
              >
                <Stack direction='row' gap={2} alignItems='center'>
                  <Typography sx={{ width: '80px' }}>{name}</Typography>
                  <Stack
                    direction='row'
                    alignItems='center'
                    sx={{
                      width: '1200px',
                      position: 'relative',
                      borderRadius: '4px',
                      height: '30px',
                      bgcolor: 'kqn.gray',
                    }}
                  >
                    <FlowSteps nodes={value} />
                  </Stack>
                </Stack>
                <Divider />
              </Stack>
            );
          })}
        </Stack>
      </Stack>
      <FlowTimestamp
        duration={totalDuration.timestampDuration}
        leftOffsetCorrectionWidth={totalDuration.leftOffsetCorrectionWidth}
      />
    </Stack>
  );
};

export default memo(FlowChart);
