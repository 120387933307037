import styled from '@emotion/styled';
import { TableCell } from '@mui/material';

export const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const TableStyled = styled.div`
  width: 100%;
`;

export const WrapperDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TableCellStyled = styled(TableCell)<{
  active?: number;
}>`
  color: ${({ active }) => (active === 1 ? '#000' : '#717174')};
`;

TableCellStyled.defaultProps = {
  active: 1,
};
