import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Paper, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { InfoRow, Optional } from '../../../components';
import { CategoryResponse } from '../../types';
import { UpdateCategoriesModal } from '../components/modals';

type Props = {
  category: CategoryResponse;
};

const CategorySummary: React.FC<Props> = ({ category }) => {
  const [openEdit, setOpenEdit] = useState(false);
  const { name, nameDe, description } = category;

  const handleOnEditClose = () => {
    setOpenEdit(false);
  };

  return (
    <Paper sx={{ minWidth: '500px', p: 3 }}>
      <Stack direction='column' gap={3} width='100%'>
        <Stack direction='row' justifyContent='space-between' alignItems='center'>
          <Typography>Category Profile</Typography>
          <EditOutlinedIcon
            className='icon'
            onClick={() => setOpenEdit(true)}
            sx={{ cursor: 'pointer' }}
          />
        </Stack>
        <Stack direction='column' gap={3} sx={{ color: 'kqn.darkGray' }}>
          <InfoRow label='Name (English)' value={name} />
          <InfoRow label='Name (German)' value={nameDe} />
          <InfoRow label='Description' value={description} />
        </Stack>
      </Stack>
      <Optional condition={openEdit}>
        <UpdateCategoriesModal category={category} isOpen={openEdit} onClose={handleOnEditClose} />
      </Optional>
    </Paper>
  );
};

export default CategorySummary;
