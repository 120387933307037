import { Box, Button, Stack } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  AssociatedRecipes,
  DetailsTableSkeleton,
  MainLayout,
  Navigation,
  Optional,
  TableStyled,
  TableWrapper,
} from '../../../components';
import { NavigationPaths } from '../../../constants';
import { queries } from '../../queries';
import AccessoriesSummary from './accessories-summary';
import DeleteAccessoryModal from './delete-accessory-modal';

const AccessoriesDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [openDelete, setOpenDelete] = useState(false);

  const { data, isLoading, isFetching } = useQuery({
    ...queries.accessories.details(id),
    enabled: !!id,
  });

  if (isLoading || isFetching) return <DetailsTableSkeleton title={data?.name} />;

  if (!data) return <div>Accessory not found</div>;

  return (
    <TableWrapper>
      <TableStyled>
        <Box sx={{ width: '100%' }}>
          <Navigation pageTitle={data.name}>
            <Stack direction='row' justifyContent='flex-end' width='100%'>
              <Button variant='outlined' color='error' onClick={() => setOpenDelete(true)}>
                Delete
              </Button>
            </Stack>
          </Navigation>
          <MainLayout>
            <Stack direction='row' gap={4} justifyContent='flex-start'>
              <AccessoriesSummary accessory={data} />
              <AssociatedRecipes recipeIds={data?.recipes?.map((recipe) => recipe.id) || []} />
            </Stack>
          </MainLayout>
        </Box>
      </TableStyled>
      <Optional condition={openDelete}>
        <DeleteAccessoryModal
          id={data.id}
          isOpen={openDelete}
          onClose={() => setOpenDelete(false)}
          onSucess={() => {
            navigate(NavigationPaths.ACCESSORIES);
          }}
        />
      </Optional>
    </TableWrapper>
  );
};

export default AccessoriesDetails;
