import FileUploadIcon from '@mui/icons-material/FileUpload';
import { Button, CircularProgress } from '@mui/material';
import React from 'react';
import { useExportRecipeFile } from './hooks';

type Props = {
  recipeId: string;
};

const ExportRecipe: React.FC<Props> = ({ recipeId }) => {
  const { handleFetchRecipeMetadata, isLoading } = useExportRecipeFile(recipeId);

  return (
    <Button
      startIcon={
        !isLoading ? (
          <FileUploadIcon />
        ) : (
          <CircularProgress size={20} sx={{ color: 'kqn.darkGray' }} />
        )
      }
      disabled={isLoading}
      onClick={() => handleFetchRecipeMetadata()}
      sx={{ fontSize: '12px', color: 'kqn.darkGray' }}
    >
      EXPORT
    </Button>
  );
};

export default ExportRecipe;
