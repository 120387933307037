import { AccessoryModel } from '../../types';

export const mapAccessoriesToCells = (accessories: AccessoryModel[]) => {
  return accessories.map((acc) => ({
    id: acc.id,
    name: acc.name,
    nameDe: acc.nameDe,
    recipes: acc.recipes,
  }));
};
