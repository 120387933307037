import { koqoonApi } from '../../../config';
import { Paths } from '../../../constants';
import { PageableQueryFilter, PaginatedResult } from '../../../types';
import { CreateUnitRequest, PageableUnit, PageableUnitResponse } from '../../types';

const basePath = Paths.UNITS;

const findById = async (unitId?: string) => {
  try {
    if (!unitId) throw new Error('Unit ID is required');

    const response = await koqoonApi.get(`${basePath}/${unitId}`);
    return response.data as PageableUnit;
  } catch (err: any) {
    throw err.response.data?.message || 'Something went wrong';
  }
};

const getUnits = async ({ query = '', page = 1, perPage = 10 }: PageableQueryFilter) => {
  const response = await koqoonApi.get(basePath, {
    params: {
      query,
      page,
      perPage,
    },
  });

  return response.data as PaginatedResult<PageableUnitResponse>;
};

const createUnit = async (unit: CreateUnitRequest) => {
  try {
    const response = await koqoonApi.post(basePath, unit);
    return response;
  } catch (err: any) {
    throw err.response.data?.message || 'Something went wrong';
  }
};

const updateUnit = async (unitId: string, unit: CreateUnitRequest) => {
  try {
    const response = await koqoonApi.put(`${basePath}/${unitId}`, unit);
    return response;
  } catch (err: any) {
    throw err.response.data?.message || 'Something went wrong';
  }
};

const activateUnits = async (data: string[]) => {
  try {
    const response = await koqoonApi.patch(`${basePath}/activate-units`, {
      names: data,
    });

    return response;
  } catch (err: any) {
    throw err.response.data?.message || 'Something went wrong';
  }
};

const removeUnit = async (unitId: string) => {
  try {
    const response = await koqoonApi.delete(`${basePath}/${unitId}`);
    return response;
  } catch (err: any) {
    throw err.response.data?.message || 'Something went wrong';
  }
};

export const unitService = {
  findById,
  getUnits,
  createUnit,
  activateUnits,
  updateUnit,
  removeUnit,
};
