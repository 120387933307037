import { FormControl, MenuItem, Select, SelectChangeEvent, Stack } from '@mui/material';
import { Title } from '../../../../../../../../../../components';
import { ErrorKeys } from '../../../../../../../../../../constants';
import { useErrorContext } from '../../../../../../../../../../context';
import { useMealActions } from '../../../../../../../../../../store/meal-store';
import { Burner, ModeType } from '../../../../../../../../../types';
import { PresetValues } from '../../../meals/hooks';
import { initialDurations, singleDuration } from '../../config';

interface Props {
  mealId: string;
  preset: string;
  stepId: string;
  method?: ModeType;
  probes?: Burner[];
  disabled?: boolean;
  methods: PresetValues<ModeType>;
}

const PresetMethod: React.FC<Props> = ({
  mealId,
  methods,
  stepId,
  probes,
  preset,
  method,
  disabled,
}) => {
  const { updateMethod, updateDurations, updateBurners } = useMealActions();
  const { error } = useErrorContext();

  const handlUpdateMethod = (e: SelectChangeEvent<string>) => {
    const selectedMethod = (methods.data || []).find((m) => m.id === e.target.value);
    if (!selectedMethod) return;
    updateMethod(mealId, stepId, selectedMethod);

    if (!preset) return;

    if (selectedMethod.name === 'Probe') {
      const isSinglePreset = preset === 'Single';
      updateDurations(mealId, stepId, singleDuration);
      updateBurners(
        mealId,
        stepId,
        isSinglePreset ? probes?.filter((p) => p.name === 'Default') || [] : probes || [],
        'probes',
      );
    } else {
      updateDurations(mealId, stepId, preset === 'Single' ? singleDuration : initialDurations);
      updateBurners(mealId, stepId, [], 'probes');
    }
  };

  return (
    <Stack direction='column' gap={1} sx={{ width: '100%' }}>
      <Title title='Grilling Method' />
      <FormControl fullWidth>
        <Select
          size='small'
          defaultValue=''
          disabled={disabled}
          value={method?.id ?? ''}
          onChange={handlUpdateMethod}
          labelId='method'
          id='method'
          data-testid='preset-method'
          error={!!error.get(`${stepId}-${ErrorKeys.METHOD}`)}
        >
          {methods.data?.map((mth) => (
            <MenuItem key={mth.id} value={mth.id} data-testid='preset-method-option'>
              {mth.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Stack>
  );
};
export default PresetMethod;
