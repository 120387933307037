import { zodResolver } from '@hookform/resolvers/zod';
import AddIcon from '@mui/icons-material/Add';
import { Stack } from '@mui/material';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { ComposerFooterButtons, KQNTextInputField, ModalComposer } from '../../../components';
import { useUpsertAccessory } from '../mutations';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const createAccessorySchema = z.object({
  name: z.string().min(1, 'Name is required'),
  nameDe: z.string().min(1, 'Name (German) is required'),
});

type CreateAccessorySchemaType = z.infer<typeof createAccessorySchema>;

const CreateAccessoryModal: React.FC<Props> = ({ isOpen, onClose }) => {
  const { handleUpsertAccessory, isLoading } = useUpsertAccessory();
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<CreateAccessorySchemaType>({
    resolver: zodResolver(createAccessorySchema),
    defaultValues: {
      name: '',
      nameDe: '',
    },
  });

  const handleOnSubmit = async (data: CreateAccessorySchemaType) => {
    await handleUpsertAccessory({
      data,
      onSuccess: () => {
        onClose();
      },
    });
  };

  return (
    <ModalComposer
      icon={<AddIcon className='icon' />}
      title='Add Accessory'
      isOpen={isOpen}
      onClose={onClose}
    >
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <Stack
          spacing={2}
          sx={{
            mt: 2,
          }}
        >
          <KQNTextInputField
            control={control}
            label='Accessory (EN)'
            name='name'
            error={errors.name}
            fullWidth
          />
          <KQNTextInputField
            control={control}
            label='Accessory (DE)'
            name='nameDe'
            error={errors.nameDe}
            fullWidth
          />
          <ComposerFooterButtons onClose={onClose} isLoading={isLoading} />
        </Stack>
      </form>
    </ModalComposer>
  );
};

export default CreateAccessoryModal;
