import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckIcon from '@mui/icons-material/Check';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { Box, Stack, Typography } from '@mui/material';
import React, { memo } from 'react';
import {
  DiscardChangesModal,
  KQNIconButton,
  MutationIndicator,
  Optional,
} from '../../../../../../../../components';
import { useMealActions } from '../../../../../../../../store/meal-store';
import { GrillStep, InfoGrillStep } from '../../../../../../../types';
import { useRecipeComposerActions } from '../../../../../../hooks';
import { useRemovePreset } from '../../mutations';
import InfoStepInstructions from './info-step-instructions';
import { useUpsertInfoStep } from './mutations/use-upsert-info-step';

type Props = {
  mealId: string;
  preset: GrillStep;
  ordinal: number;
  onCloseEdit: () => void;
};

const InfoStep: React.FC<Props> = ({ mealId, preset, ordinal, onCloseEdit }) => {
  const { removePreset, updatePresets } = useMealActions();
  const { isOpen, handleClose, handleOpen } = useRecipeComposerActions();
  const { handleOnRemove, isLoading } = useRemovePreset();
  const { handleUpsertInfoGrillStep, isLoading: isSaving } = useUpsertInfoStep();
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: preset.id || '',
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const handleRemovePreset = () => {
    removePreset(mealId, preset.id);
    handleClose();
  };

  const handleDeleteStep = () => {
    handleOnRemove({ mealId, presetId: preset.id });
    handleClose();
  };

  const handleSaveStep = async () => {
    if (isLoading || isSaving) return;
    if (!preset?.durations?.[0] || !mealId) return;

    const infoStep = {
      id: preset.id,
      isDraft: preset.isDraft,
      type: preset.type,
      userInstruction: preset.userInstruction,
      userInstructionDe: preset.userInstructionDe,
      description: preset.description,
      descriptionDe: preset.descriptionDe,
      ordinal: preset.ordinal,
      durations: [preset.durations[0]],
    } as InfoGrillStep;

    await handleUpsertInfoGrillStep({
      mealId,
      preset: infoStep,
      onUpdate: updatePresets,
    });
  };

  return (
    <Box
      ref={setNodeRef}
      style={style}
      {...attributes}
      display='flex'
      flexDirection='column'
      sx={{
        overflowY: 'auto',
        boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.40)',
        borderRadius: '2px',
        bgcolor: 'kqn.white',
        px: 3,
      }}
    >
      <Stack direction='row' gap={3} sx={{ width: '100%', py: 1.5 }}>
        <Stack direction='column' width='100%' gap={2}>
          <Stack direction='row' gap={2} alignItems='center' justifyContent='space-between'>
            <Stack direction='row' gap={1} alignItems='center'>
              <DragIndicatorIcon {...listeners} sx={{ color: 'kqn.darkerGray', cursor: 'grab' }} />
              <Typography variant='body2' sx={{ fontWeight: 600, color: 'kqn.darkerGray' }}>
                Step {ordinal} {preset.isDraft ? '(Draft)' : '(Editing)'}
              </Typography>
            </Stack>
            <Stack direction='row' gap={1}>
              <MutationIndicator isMutating={isLoading || isSaving} />
              <KQNIconButton onClick={handleSaveStep} isDisabled={isLoading}>
                <CheckIcon sx={{ color: 'kqn.green' }} />
              </KQNIconButton>
              <KQNIconButton onClick={handleOpen} isDisabled={isLoading}>
                <DeleteOutlineIcon sx={{ color: 'kqn.coral' }} />
              </KQNIconButton>
              <Optional condition={!preset.isDraft}>
                <KQNIconButton onClick={onCloseEdit} isDisabled={isLoading}>
                  <CancelOutlinedIcon sx={{ color: 'kqn.coral' }} />
                </KQNIconButton>
              </Optional>
            </Stack>
          </Stack>
          <InfoStepInstructions mealId={mealId} step={preset} />
        </Stack>
      </Stack>
      <DiscardChangesModal
        isOpen={isOpen}
        onClose={handleClose}
        title='Are you sure you want to remove this grill step?'
        content='This step will be removed permanently.'
        onConfirm={preset.isDraft ? handleRemovePreset : handleDeleteStep}
      />
    </Box>
  );
};

export default memo(InfoStep);
