import { useMutation } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { koqoonApi } from '../../../../../config';

export const useExportRecipeFile = (recipeId: string) => {
  const mutation = useMutation(
    () => koqoonApi.get(`/recipes/export?recipeId=${recipeId}`, { responseType: 'blob' }),
    {
      onSuccess: (response) => {
        const filename =
          response.headers['content-disposition'].split('filename=')[1].split('.')[0] ||
          'recipe.json';
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${filename}.json`); // or any other extension
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      },
      onError: (error) => {
        toast.error('Error exporting the recipe');
      },
    },
  );

  return { handleFetchRecipeMetadata: mutation.mutate, isLoading: mutation.isLoading };
};
