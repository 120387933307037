import SearchIcon from '@mui/icons-material/Search';
import { Box, InputAdornment, Stack } from '@mui/material';
import React from 'react';
import { KQNTextField } from '..';
import { useSearch } from '../../context';

const SearchFilter: React.FC = () => {
  const { setQuery, query } = useSearch();

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
  };

  return (
    <Box data-testid='search-filter' width='100%' sx={{ borderRadius: 1 }}>
      <Stack direction='row' justifyContent='space-between' alignItems='center' gap={2}>
        <KQNTextField
          size='small'
          sx={{ width: 'calc(40% - 8px)' }}
          label='Search'
          data-testid='search-filter-input'
          onChange={handleSearch}
          value={query}
          InputProps={{
            endAdornment: (
              <InputAdornment position='start'>{!query && <SearchIcon />}</InputAdornment>
            ),
          }}
          InputLabelProps={{ sx: { color: 'kqn.darkerGray' } }}
          placeholder='Search'
          type='search'
          role='search'
          aria-label='search'
        />
      </Stack>
    </Box>
  );
};

export default SearchFilter;
