import { Table, TableContainer } from '@mui/material';
import React from 'react';
import { EnhancedTableHead, Pagination } from '../../../../components';
import { PaginatedResult } from '../../../../types';
import { PageableRecipe, RecipeHeadCells } from '../../../types';
import RecipesEnhancedTableBody from './recipes-enhanched-table-body';
import recipesHeadCells from './recipes-head-cells';

interface Props {
  data: PaginatedResult<PageableRecipe>;
  selected: string[];
  order: 'asc' | 'desc';
  page: number;
  perPage: number;
  orderBy: keyof RecipeHeadCells;
  onPageChange: (event: unknown, newPage: number) => void;
  onRowsPerPageChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleOnSelect: (event: React.MouseEvent<unknown>, name: string) => void;
  handleRequestSort: (
    event: React.MouseEvent<unknown, MouseEvent>,
    property: keyof RecipeHeadCells,
  ) => void;
}

const RecipesEnhancedTable: React.FC<Props> = ({
  data,
  order,
  orderBy,
  page,
  perPage,
  selected,
  onPageChange,
  onRowsPerPageChange,
  handleOnSelect,
  handleRequestSort,
}) => {
  return (
    <>
      <TableContainer
        sx={{ maxHeight: 'calc(100vh - 350px)', overflow: 'auto', borderRadius: '2px' }}
      >
        <Table sx={{ minWidth: 750 }} aria-labelledby='tableTitle' size='medium'>
          <EnhancedTableHead
            headCells={recipesHeadCells}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <RecipesEnhancedTableBody
            data={data}
            perPage={perPage}
            order={order}
            orderBy={orderBy}
            handleOnSelect={handleOnSelect}
            selected={selected}
          />
        </Table>
      </TableContainer>
      <Pagination
        total={data.meta.total}
        perPage={perPage}
        page={page}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
      />
    </>
  );
};

export default RecipesEnhancedTable;
