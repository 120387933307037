import { CssBaseline } from '@mui/material';
import Box from '@mui/material/Box';
import { useState } from 'react';
import { useAuthentication } from '../../context';
import RecipeMenuItems from './recipe-menu-items';
import SidebarAppBar from './sidebar-appbar';
import SidebarDrawer from './sidebar-drawer';
import SidebarMain from './sidebar-main';
import SidebarDrawerHeader from './sidebardrawer-header';
import UserMenuItems from './user-menu-items';

interface Props {
  children: React.ReactNode;
}

const Sidebar: React.FC<Props> = ({ children }) => {
  const { user, isLoading } = useAuthentication();
  const [open, setOpen] = useState(true);

  if (!user && !isLoading) return null;

  const handleDrawerOpen = () => {
    setOpen((prev) => !prev);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <SidebarAppBar user={user} handleDrawerOpen={handleDrawerOpen} open={open} />
      <SidebarDrawer open={open}>
        <SidebarDrawerHeader />
        <RecipeMenuItems open={open} />
        <UserMenuItems open={open} />
      </SidebarDrawer>
      <SidebarMain open={open}>
        <SidebarDrawerHeader />
        {children}
      </SidebarMain>
    </Box>
  );
};

export default Sidebar;
