import AddIcon from '@mui/icons-material/Add';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { ModalComposer } from '../../../../components';
import { capitalizeLetter } from '../../../../shared';
import { queries } from '../../../queries';
import { CreateNutrientRequest, Ingredient, Nutrient } from '../../../types';
import { mapUnitsResponseToObject } from '../../../units';
import { useUpgradeIngredient } from '../../mutations';
import { IngredientUpdateSchemaType, UpsertIngredientForm } from '../forms';
import { useFormChangeActions } from './hooks';

interface Props {
  ingredient: Ingredient;
  isOpen: boolean;
  onClose: () => void;
}

const UpdateIngredientModal: React.FC<Props> = ({ ingredient, isOpen, onClose }) => {
  const { handleUpdateIngredient, isLoading } = useUpgradeIngredient();
  const [selectedUnits, setSelectedUnits] = useState<string[]>(
    ingredient.units.map((unit) => unit.name),
  );
  const [nutrientValues, setNutrientValues] = useState<Nutrient[]>(ingredient.nutrients);

  const { handleChange, handleNutrientChange } = useFormChangeActions({
    setNutrientValues,
    setSelectedUnits,
  });

  const { data: units } = useQuery({
    ...queries.units.all(),
    select: (data) => mapUnitsResponseToObject(data).data,
    staleTime: 0,
    refetchOnMount: true,
  });

  const handleOnSubmit = async (data: IngredientUpdateSchemaType) => {
    const body = {
      name: capitalizeLetter(data.name),
      nameDe: capitalizeLetter(data.nameDe),
      units: (units || [])
        .filter((unit) => selectedUnits.includes(unit.name))
        .map((unit) => unit.id),
      nutrients: nutrientValues.map((nutrient) => ({
        nutrientId: nutrient.id,
        amount: nutrient.amount,
      })) as CreateNutrientRequest[],
    };

    await handleUpdateIngredient({
      ingredientId: ingredient.id,
      data: body,
      onSuccess: () => {
        onClose();
      },
    });
  };

  return (
    <ModalComposer
      icon={<AddIcon className='icon' />}
      title='Edit Ingredient'
      isOpen={isOpen}
      onClose={onClose}
    >
      <UpsertIngredientForm
        ingredient={ingredient}
        onClose={onClose}
        units={units || []}
        isSubmitting={isLoading}
        nutrients={nutrientValues}
        onSubmit={handleOnSubmit}
        onUnitChange={handleChange}
        selectedUnits={selectedUnits}
        onNutrientChange={handleNutrientChange}
      />
    </ModalComposer>
  );
};

export default UpdateIngredientModal;
