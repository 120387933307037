import { Box } from '@mui/system';
import { ImageFile } from '../../../types';

type Props = {
  file?: ImageFile;
  imageUrl?: string;
};

const ImagePreview: React.FC<Props> = ({ file, imageUrl }) => {
  const hasImage = file || imageUrl;

  return (
    <Box
      data-testid='image-preview'
      display='flex'
      justifyContent='center'
      alignItems='center'
      sx={{ height: '330px', bgcolor: 'kqn.gray', borderRadius: '8px' }}
    >
      {hasImage && (
        <img
          src={file?.preview || imageUrl}
          alt={file?.name}
          style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '8px' }}
        />
      )}
    </Box>
  );
};

export default ImagePreview;
