import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Paper, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { InfoRow, Optional } from '../../../components';
import { PageableUnit } from '../../types';
import { UpdateUnitModal } from './modals';

type Props = {
  unit: PageableUnit;
};

const UnitSummary: React.FC<Props> = ({ unit }) => {
  const [openEdit, setOpenEdit] = useState(false);
  const { name, nameDe, namePlural, nameDePlural } = unit;

  const handleOnEditClose = () => {
    setOpenEdit(false);
  };
  return (
    <Paper sx={{ width: '50%', p: 3 }}>
      <Stack direction='column' gap={3} width='100%'>
        <Stack direction='row' justifyContent='space-between' alignItems='center'>
          <Typography>Unit Profile</Typography>
          <EditOutlinedIcon
            className='icon'
            onClick={() => setOpenEdit(true)}
            sx={{ cursor: 'pointer' }}
          />
        </Stack>
        <Stack direction='column' gap={3} sx={{ color: 'kqn.darkGray' }}>
          <InfoRow label='Name (English)' value={name} />
          <InfoRow label='Name (German)' value={nameDe} />
          <InfoRow label='Name Plural (English)' value={namePlural} />
          <InfoRow label='Name Plural (German)' value={nameDePlural} />
        </Stack>
      </Stack>
      <Optional condition={openEdit}>
        <UpdateUnitModal unit={unit} isOpen={openEdit} onClose={handleOnEditClose} />
      </Optional>
    </Paper>
  );
};

export default UnitSummary;
