import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useRecipesFilter } from '../../../../context';
import { fetchConfig, useDebounceValue, useHandleFilterParams } from '../../../../shared';
import { queries } from '../../../queries';
import { mapRecipeResponseToObject } from './mappers';
import RecipesTableView from './recipes-table-view';

const RecipesTable: React.FC = () => {
  const [_searchParams, setSearchParams] = useSearchParams();
  const { page, perPage, orderBy } = useHandleFilterParams();
  const { query: filterQuery, data: filterData } = useRecipesFilter();
  const { category, complexity, cookingStart, cookingEnd, mealType, state } = filterData;
  const debouncedQuery = useDebounceValue(filterQuery);

  const { data } = useQuery({
    ...queries.recipes.filter({
      query: debouncedQuery,
      perPage,
      page,
      orderBy,
      complexity: complexity?.join(','),
      category: category?.join(','),
      mealType: mealType?.join(','),
      state,
      cookingStart,
      cookingEnd,
    }),
    keepPreviousData: true,
    refetchOnMount: true,
    refetchOnReconnect: true,
    suspense: true,
  });

  useEffect(() => {
    if (debouncedQuery === '') return;

    setSearchParams({ page: String(1), perPage: perPage.toString() });
  }, [debouncedQuery, perPage, setSearchParams]);

  if (!data) return null;

  const recipesResponse = mapRecipeResponseToObject(data);

  const handleChangePage = (_: unknown, newPage: number) => {
    setSearchParams({ page: String(newPage + 1), perPage: perPage.toString(), orderBy });
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (fetchConfig.rowsPerPageOptions.includes(Number(event.target.value))) {
      setSearchParams({ page: '1', perPage: event.target.value, orderBy });
      return;
    }

    setSearchParams({ page: '1', perPage: '50', orderBy });
  };

  return (
    <RecipesTableView
      data={recipesResponse}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  );
};

export default RecipesTable;
