import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Chip, Paper, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { InfoRow, Optional } from '../../../components';
import { Ingredient } from '../../types';
import { UpdateIngredientModal } from './modals';

type Props = {
  ingredient: Ingredient;
};

const IngredientSummary: React.FC<Props> = ({ ingredient }) => {
  const [openEdit, setOpenEdit] = useState(false);
  const { nutrients, units, name, nameDe } = ingredient;

  const handleOnEditClose = () => {
    setOpenEdit(false);
  };

  return (
    <Paper sx={{ minWidth: '500px', p: 3 }}>
      <Stack direction='column' gap={3} width='100%'>
        <Stack direction='row' justifyContent='space-between' alignItems='center'>
          <Typography>Ingredient Profile</Typography>
          <EditOutlinedIcon
            className='icon'
            onClick={() => setOpenEdit(true)}
            sx={{ cursor: 'pointer' }}
          />
        </Stack>
        <Stack direction='column' gap={3} sx={{ color: 'kqn.darkGray' }}>
          <InfoRow label='Name (English)' value={name} />
          <InfoRow label='Name (German)' value={nameDe} />
          <Stack direction='row' justifyContent='space-between' alignItems='center'>
            <Stack width='100%' justifyContent='flex-start'>
              <Typography variant='body1'>Units</Typography>
            </Stack>
            <Stack
              justifyContent='start'
              width='100%'
              gap={1}
              direction='row'
              alignItems='center'
              flex-wrap='wrap'
            >
              {units.map((unit) => (
                <Chip
                  key={unit.id}
                  label={unit.name}
                  sx={{ bgcolor: 'kqn.ultraLightCooper', borderRadius: '4px' }}
                />
              ))}
            </Stack>
          </Stack>
          {nutrients.map((nutrient) => (
            <InfoRow
              key={nutrient.id}
              label={`${nutrient.name}, ${nutrient.unit.name}`}
              value={String(nutrient.amount.toString().replace('.', ','))}
            />
          ))}
        </Stack>
      </Stack>
      <Optional condition={openEdit}>
        <UpdateIngredientModal
          ingredient={ingredient}
          isOpen={openEdit}
          onClose={handleOnEditClose}
        />
      </Optional>
    </Paper>
  );
};

export default IngredientSummary;
