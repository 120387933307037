import { zodResolver } from '@hookform/resolvers/zod';
import { Stack, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { ComposerFooterButtons, KQNTextInputField } from '../../../../components';
import { PageableUnit } from '../../../types';
import { UnitSchemaType, upsertUnitSchema } from './schema';

type Props = {
  unit?: PageableUnit;
  onSubmit: (data: UnitSchemaType) => void;
  onClose: () => void;
  isSubmitting?: boolean;
};

const UpsertUnitForm: React.FC<Props> = ({ unit, onSubmit, onClose, isSubmitting }) => {
  const { control, handleSubmit } = useForm<UnitSchemaType>({
    resolver: zodResolver(upsertUnitSchema),
    defaultValues: {
      name: unit?.name || '',
      nameDe: unit?.nameDe || '',
      namePlural: unit?.namePlural || '',
      namePluralDe: unit?.nameDePlural || '',
    },
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack pt={2} gap={2}>
        <Typography variant='body1'>Singular Name</Typography>
        <Stack
          direction='row'
          justifyContent='space-between'
          gap={2}
          alignContent='center'
          alignItems='center'
        >
          <KQNTextInputField
            name='name'
            control={control}
            label='Unit (EN)'
            size='small'
            fullWidth
            placeholder='Unit (EN)'
          />
          <KQNTextInputField
            label='Unit (DE)'
            placeholder='Unit (DE)'
            name='nameDe'
            control={control}
          />
        </Stack>
        <Typography variant='body1'>Plural Name</Typography>
        <Stack
          direction='row'
          justifyContent='space-between'
          gap={2}
          alignContent='center'
          alignItems='center'
        >
          <KQNTextInputField
            label='Unit Plural (EN)'
            placeholder='Unit Plural (EN)'
            name='namePlural'
            control={control}
          />
          <KQNTextInputField
            label='Unit Plural (DE)'
            size='small'
            placeholder='Unit Plural (DE)'
            name='namePluralDe'
            control={control}
          />
        </Stack>
        <ComposerFooterButtons onClose={onClose} isLoading={isSubmitting} />
      </Stack>
    </form>
  );
};

export default UpsertUnitForm;
