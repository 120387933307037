import CircleIcon from '@mui/icons-material/Circle';
import CloseIcon from '@mui/icons-material/Close';
import { Box, LinearProgress, Stack, Typography } from '@mui/material';
import { bytesToSize } from '../../../modules/images/helpers';
import { ImageFile } from '../../../types';

type Props = {
  file?: ImageFile;
  progress: number;
  status?: 'preview' | 'loading' | 'completed' | 'failed';
  onCancel?: () => void;
  imageUrl?: string;
};

const ImageIndicatorInfo: React.FC<Props> = ({ file, progress, status, onCancel, imageUrl }) => {
  if (!file) return null;

  return (
    <Stack direction='row' justifyContent='space-between' py={2} data-testid='image-indicator-info'>
      <Stack alignItems='center' direction='row' gap={2} width='100%'>
        <Stack direction='column' justifyContent='flex-start' width='100%'>
          <Typography sx={{ m: 0 }}>{file?.name}</Typography>
          <Stack direction='column' gap={1}>
            <Box
              display='flex'
              flexDirection='row'
              gap={1}
              alignItems='center'
              sx={{ textTransform: 'capitalize', color: 'kqn.darkGray' }}
            >
              <Typography sx={{ m: 0 }}>{bytesToSize(file?.size || 0)}</Typography>
              <CircleIcon sx={{ width: '6px' }} />
              <Typography
                sx={{
                  m: 0,
                }}
              >
                {status}
              </Typography>
            </Box>

            {status === 'loading' && (
              <LinearProgress
                variant='determinate'
                value={progress}
                sx={{
                  bgcolor: '#58757099',
                  '& .MuiLinearProgress-bar': { bgcolor: 'kqn.green' },
                }}
              />
            )}
          </Stack>
        </Stack>
      </Stack>
      {!imageUrl && (
        <Box
          sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          onClick={onCancel}
        >
          <CloseIcon />
        </Box>
      )}
    </Stack>
  );
};

export default ImageIndicatorInfo;
