import { Box, Checkbox, TableBody, TableCell, TableRow, Tooltip } from '@mui/material';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AssignedRecipes, Optional, TableCellStyled } from '../../../../components';
import { getComparator, Order, stableSort } from '../../../../shared';
import { PaginatedResult } from '../../../../types';
import { CategoriesHeadCells, CategoryResponse } from '../../../types';
import mapIngredientsToCells from '../../mapper/map-categories-to-cells';

type Props = {
  data: PaginatedResult<CategoryResponse>;
  selected: string[];
  perPage: number;
  order: Order;
  orderBy: keyof CategoriesHeadCells;
  handleOnSelect: (event: React.MouseEvent<unknown, MouseEvent>, id: string) => void;
};

const CategoriesTableBody: React.FC<Props> = ({
  data,
  perPage,
  order,
  orderBy,
  handleOnSelect,
  selected,
}) => {
  const navigate = useNavigate();
  const { data: categories } = data;
  const mappedCategories = mapIngredientsToCells(categories);
  const [isOpen, setIsOpen] = useState(false);
  const isSelected = (id: string) => selected.indexOf(id) !== -1;
  const [recipes, setRecipes] = useState<string[]>([]);
  const records =
    orderBy !== 'name' && orderBy !== 'nameDe'
      ? stableSort(mappedCategories, getComparator(order, orderBy))
      : mappedCategories;
  const rows = useMemo(() => records.slice(0, perPage), [perPage, records]);

  const handleRecipes = (id: string) => {
    const recipesArr = categories.find((cat) => cat.id === id)?.recipes.map((recipe) => recipe.id);
    if (recipesArr && recipesArr.length > 0) {
      setIsOpen(true);
      setRecipes(recipesArr);
    }
  };

  const handleOnClose = () => {
    setIsOpen(false);
    setRecipes([]);
  };

  const handleOnClick = (id: string) => {
    navigate(`/recipes/categories/${id}`);
  };

  return (
    <TableBody>
      {rows.map((row, index) => {
        const isItemSelected = isSelected(row.id);
        const labelId = `enhanced-table-checkbox-${index}`;

        return (
          <TableRow
            hover
            onClick={(event) => handleOnSelect(event, row.id)}
            role='checkbox'
            aria-checked={isItemSelected}
            tabIndex={-1}
            key={row.id}
            selected={isItemSelected}
            sx={{ cursor: 'pointer' }}
          >
            <TableCell padding='checkbox'>
              <Checkbox
                color='primary'
                checked={isItemSelected}
                inputProps={{ 'aria-labelledby': labelId }}
              />
            </TableCell>
            <TableCellStyled
              component='th'
              id={labelId}
              scope='row'
              onClick={() => handleOnClick(row.id)}
            >
              {row.name}
            </TableCellStyled>
            <TableCellStyled align='center' onClick={() => handleOnClick(row.id)}>
              {row.nameDe}
            </TableCellStyled>
            <TableCellStyled align='center' onClick={() => handleOnClick(row.id)}>
              {row.description}
            </TableCellStyled>
            <TableCell
              align='center'
              onClick={() => handleRecipes(row.id)}
              sx={{
                fontWeight: 'bold',
                '&:hover': {
                  cursor: 'pointer',
                  color: 'kqn.cooper',
                  textDecoration: 'underline',
                },
              }}
            >
              <Tooltip
                title='Click to display recipes'
                placement='left'
                PopperProps={{
                  sx: {
                    '& .MuiTooltip-tooltip': {
                      bgcolor: '#e4e4e4',
                      color: 'kqn.darkerGray',
                      fontSize: '14px',
                      lineHeight: '20px',
                      py: '8px',
                      px: '12px',
                      borderRadius: '8px',
                    },
                  },
                }}
              >
                <Box component='span' px={2}>
                  {`${row.recipeCount}  ${Number(row.recipeCount) === 1 ? 'recipe' : 'recipes'}`}
                </Box>
              </Tooltip>
            </TableCell>
          </TableRow>
        );
      })}
      <Optional condition={isOpen}>
        <AssignedRecipes recipeIds={recipes} isOpen={isOpen} onClose={handleOnClose} />
      </Optional>
    </TableBody>
  );
};

export default CategoriesTableBody;
