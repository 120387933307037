import { Stack } from '@mui/material';
import React, { useCallback } from 'react';
import { KQNTextField, TextInputAndormnet, Title } from '../../../../../../../../../../components';
import { ErrorKeys } from '../../../../../../../../../../constants';
import { useErrorContext } from '../../../../../../../../../../context';
import { useDebounceValue } from '../../../../../../../../../../shared';
import { useMeals } from '../../../../../../../../../../store/meal-store';
import { GrillStep } from '../../../../../../../../../types';

type Props = {
  mealId: string;
  step: GrillStep;
};

const PresetInfo: React.FC<Props> = ({ mealId, step }) => {
  const updatePreset = useMeals((state) => state.actions.updatePreset);
  const { error } = useErrorContext();

  const [inputValues, setInputValues] = React.useState({
    description: step.description || '',
    descriptionDe: step.descriptionDe || '',
    userInstruction: step.userInstruction || '',
    userInstructionDe: step.userInstructionDe || '',
  });
  const debouncedValue = useDebounceValue(inputValues, 300);
  React.useEffect(() => {
    updatePreset(mealId, step.id, debouncedValue);
  }, [debouncedValue, mealId, step.id, updatePreset]);

  const handleUpdateGrillStep = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, field: keyof GrillStep) => {
      setInputValues((prevInputValues) => ({
        ...prevInputValues,
        [field]: e.target.value,
      }));
    },
    [],
  );

  return (
    <Stack direction='row' justifyContent='space-between' gap={2} sx={{ width: '100%' }}>
      <Stack direction='column' gap={1} width='100%'>
        <Title title='User Instructions' />
        <KQNTextField
          size='small'
          type='text'
          fullWidth
          required
          placeholder='User instruction EN'
          value={inputValues.userInstruction}
          onChange={(e) => handleUpdateGrillStep(e, 'userInstruction')}
          InputProps={{
            sx: { bgcolor: 'kqn.white' },
            startAdornment: <TextInputAndormnet text='EN' />,
          }}
          error={!!error.get(`${step.id}-${ErrorKeys.USER_INSTRUCTION}`)}
          helperText={error.get(`${step.id}-${ErrorKeys.USER_INSTRUCTION}`)}
        />
        <KQNTextField
          size='small'
          type='text'
          fullWidth
          required
          placeholder='User instruction DE'
          value={inputValues.userInstructionDe}
          onChange={(e) => handleUpdateGrillStep(e, 'userInstructionDe')}
          InputProps={{
            sx: { bgcolor: 'kqn.white' },
            startAdornment: <TextInputAndormnet text='DE' />,
          }}
          error={!!error.get(`${step.id}-${ErrorKeys.USER_INSTRUCTION_DE}`)}
          helperText={error.get(`${step.id}-${ErrorKeys.USER_INSTRUCTION_DE}`)}
        />
      </Stack>
      <Stack direction='column' gap={1} width='100%'>
        <Title title='Title' />
        <KQNTextField
          size='small'
          type='text'
          fullWidth
          required
          placeholder='Description EN'
          value={inputValues.description}
          onChange={(e) => handleUpdateGrillStep(e, 'description')}
          InputProps={{
            sx: { bgcolor: 'kqn.white' },
            startAdornment: <TextInputAndormnet text='EN' />,
          }}
          error={!!error.get(`${step.id}-${ErrorKeys.DESCRIPTION}`)}
          helperText={error.get(`${step.id}-${ErrorKeys.DESCRIPTION}`)}
        />
        <KQNTextField
          size='small'
          type='text'
          placeholder='Description DE'
          fullWidth
          required
          value={inputValues.descriptionDe}
          onChange={(e) => handleUpdateGrillStep(e, 'descriptionDe')}
          InputProps={{
            sx: { bgcolor: 'kqn.white' },
            startAdornment: <TextInputAndormnet text='DE' />,
          }}
          error={!!error.get(`${step.id}-${ErrorKeys.DESCRIPTION_DE}`)}
          helperText={error.get(`${step.id}-${ErrorKeys.DESCRIPTION_DE}`)}
        />
      </Stack>
    </Stack>
  );
};
export default PresetInfo;
