import { SelectChangeEvent } from '@mui/material';
import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { FilterType, Range } from '../../types';
import { combineRanges } from './helpers';

export const durations: Range[] = [
  { start: 0, end: 10, value: '< 10 min' },
  { start: 10, end: 30, value: '10 - 30 min' },
  { start: 30, end: 60, value: '30 - 60 min' },
  { start: 0, end: 999, value: '> 60 min' },
];

interface FilterProps {
  categories: FilterType[];
  complexities: FilterType[];
  mealTypes: FilterType[];
}

type RecipesFilterContextType = {
  query: string;
  filters: FilterProps;
  data: {
    complexity: string[];
    category: string[];
    mealType: string[];
    cookingTime: string[];
    cookingStart?: number;
    cookingEnd?: number;
    state: string;
  };
  setValue: (event: SelectChangeEvent<any>) => void;
  setQuery: (searchQuery: string) => void;
  resetFilter: () => void;
  setInitialValues: (complexity: string[], category: string[], mealType: string[]) => void;
  setFilters: (filters: FilterProps) => void;
};

const recipeFilterDefaultValues: RecipesFilterContextType = {
  query: '',
  filters: {
    categories: [],
    complexities: [],
    mealTypes: [],
  },
  data: {
    complexity: [],
    category: [],
    mealType: [],
    cookingTime: [],
    cookingStart: 0,
    cookingEnd: undefined,
    state: '',
  },
  setValue: () => {},
  setQuery: () => {},
  resetFilter: () => {},
  setInitialValues: () => {},
  setFilters: () => {},
};

const RecipesFilterContext = createContext<RecipesFilterContextType>(recipeFilterDefaultValues);

type Props = {
  children?: React.ReactNode;
};

export const FilterContextProvider: React.FC<Props> = ({ children }) => {
  const [search, setSearch] = useState<string>('');
  const [values, setValues] = useState(recipeFilterDefaultValues);
  const [filters, setFilters] = useState<FilterProps>({
    categories: [],
    complexities: [],
    mealTypes: [],
  });

  const setInitialValues = useCallback(
    (complexity: string[], category: string[], mealType: string[]) => {
      setValues((prev) => ({
        ...prev,
        data: {
          ...prev.data,
          complexity,
          category,
          mealType,
        },
      }));
    },
    [],
  );

  const handleSearch = useCallback((searchQuery: string) => {
    setSearch(searchQuery);
  }, []);

  const handleReset = useCallback(() => {
    setValues(recipeFilterDefaultValues);
    setSearch('');
  }, []);

  const handleChange = useCallback((event: SelectChangeEvent<string[]>) => {
    const {
      target: { value, name },
    } = event as any;

    if (name === 'cookingTime') {
      const ranges = durations.filter((item) => value.includes(item.value));
      const combinedRanges = combineRanges(ranges);

      setValues((prev) => ({
        ...prev,
        data: {
          ...prev.data,
          [name]: value,
          cookingStart: combinedRanges?.start || 0,
          cookingEnd: combinedRanges?.end || 99999,
        },
      }));

      return;
    }

    setValues((prev) => ({
      ...prev,
      data: {
        ...prev.data,
        [name]: value,
      },
    }));
  }, []);

  const value = useMemo(
    () => ({
      query: search,
      filters,
      setFilters,
      data: values.data,
      setValue: handleChange,
      setQuery: handleSearch,
      resetFilter: handleReset,
      setInitialValues,
    }),
    [filters, handleChange, handleReset, handleSearch, search, setInitialValues, values.data],
  );

  return <RecipesFilterContext.Provider value={value}>{children}</RecipesFilterContext.Provider>;
};

export const useRecipesFilter = () => {
  const context = useContext(RecipesFilterContext);
  if (context === undefined) {
    throw new Error('useRecipesFilter must be used within a RecipesFilterProvider');
  }

  return context;
};
