interface RouteInfo {
  pathname?: string;
  icon?: any;
  title: string;
  path: string;
}

export const routes: RouteInfo[] = [
  {
    title: 'Recipes',
    pathname: '/recipes/overview',
    path: 'recipes',
  },
  {
    title: 'Overview',
    icon: 'fluent-emoji-high-contrast:fork-and-knife',
    pathname: '/recipes/overview',
    path: 'overview',
  },
  {
    title: 'Details',
    path: 'details',
  },
  {
    title: 'Create',
    pathname: '/recipes/create',
    path: 'create',
  },
  {
    title: 'Categories',
    icon: 'la:list-alt',
    pathname: '/recipes/categories',
    path: 'categories',
  },
  {
    title: 'Ingredients',
    icon: 'fluent:food-carrot-20-regular',
    pathname: '/recipes/ingredients',
    path: 'ingredients',
  },
  {
    title: 'Units',
    icon: 'ion:scale-outline',
    pathname: '/recipes/units',
    path: 'units',
  },
  {
    title: 'Admin',
    path: 'admin',
  },
  {
    title: 'Users',
    icon: 'system-uicons:users',
    pathname: '/admin/users',
    path: 'users',
  },
  {
    title: 'Settings',
    icon: 'codicon:settings',
    pathname: '/admin/settings',
    path: 'settings',
  },
  {
    title: 'Accessories',
    icon: 'bi:tools',
    pathname: '/recipes/accessories',
    path: 'accessories',
  },
];
