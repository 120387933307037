import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { Box, CircularProgress } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ActionMenu, Optional } from '../../../../components';
import { CopyIcon, EditIcon } from '../../../../shared';
import { ActionMenuItem } from '../../../../types';
import { RecipeBaseModel } from '../../../types';
import { useExportRecipeFile } from '../export-recipe';
import { DeleteRecipeModal, DuplicateRecipeModal } from '../modals';

type Props = {
  recipe: RecipeBaseModel;
};

const RecipeActions: React.FC<Props> = ({ recipe }) => {
  const navigate = useNavigate();
  const [openDuplicate, setOpenDuplicate] = useState<boolean>(false);
  const [deletePrompt, setDeletePrompt] = useState<boolean>(false);
  const { handleFetchRecipeMetadata, isLoading } = useExportRecipeFile(recipe.id);

  const options: ActionMenuItem[] = [
    {
      name: 'Edit',
      icon: <EditIcon />,
      fn: () => navigate(`/recipes/create?recipeId=${recipe.id}`),
    },
    { name: 'Duplicate', icon: <CopyIcon />, fn: () => setOpenDuplicate(true) },
    {
      name: 'Export',
      icon: isLoading ? (
        <CircularProgress size={20} sx={{ color: 'kqn.darkGray' }} />
      ) : (
        <FileUploadIcon />
      ),
      fn: () => handleFetchRecipeMetadata(),
    },
    {
      name: 'Delete',
      icon: <DeleteOutlineIcon sx={{ color: 'kqn.coral' }} />,
      fn: () => setDeletePrompt(true),
    },
  ];

  return (
    <Box sx={{ position: 'absolute', bottom: 0, right: 0 }}>
      <ActionMenu options={options} />
      <Optional condition={openDuplicate}>
        <DuplicateRecipeModal
          recipe={recipe}
          isOpen={openDuplicate}
          onClose={(event) => {
            event?.stopPropagation();
            setOpenDuplicate(false);
          }}
        />
      </Optional>
      <Optional condition={deletePrompt}>
        <DeleteRecipeModal
          isOpen={deletePrompt}
          onClose={(event) => {
            event?.stopPropagation();
            setDeletePrompt(false);
          }}
          recipeId={recipe?.id}
        />
      </Optional>
    </Box>
  );
};

export default RecipeActions;
