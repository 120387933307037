import { Stack } from '@mui/material';
import React from 'react';
import { Title } from '../../../../../../../../../components';
import { InputView } from '../../../../../../../../../shared/components';

type Props = {
  prepInterval?: number;
};

const PreparationIntervalView: React.FC<Props> = ({ prepInterval }) => {
  return (
    <Stack direction='column' gap={1}>
      <Title title='Preparation, s' />
      <InputView
        inputProps={{
          sx: { bgcolor: 'kqn.white', width: '110px' },
        }}
        value={prepInterval}
      />
    </Stack>
  );
};

export default PreparationIntervalView;
