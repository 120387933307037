import { zodResolver } from '@hookform/resolvers/zod';
import AddIcon from '@mui/icons-material/Add';
import { Stack } from '@mui/material';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { ComposerFooterButtons, KQNTextInputField, ModalComposer } from '../../../components';
import { AccessoryModel } from '../../types';
import { useUpsertAccessory } from '../mutations';

interface Props {
  accessory: AccessoryModel;
  isOpen: boolean;
  onClose: () => void;
}

const updateAccessorySchema = z.object({
  id: z.string().optional(),
  name: z.string().min(1, 'Name is required'),
  nameDe: z.string().min(1, 'Name (German) is required'),
  description: z.string().optional(),
});

type UpdateAccessorySchemaType = z.infer<typeof updateAccessorySchema>;

const UpdateAccessoryModal: React.FC<Props> = ({ accessory, isOpen, onClose }) => {
  const { handleUpsertAccessory, isLoading } = useUpsertAccessory();
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<UpdateAccessorySchemaType>({
    resolver: zodResolver(updateAccessorySchema),
    defaultValues: {
      id: accessory.id,
      name: accessory.name,
      nameDe: accessory.nameDe,
      description: accessory.description,
    },
  });

  const handleOnSubmit = async (data: UpdateAccessorySchemaType) => {
    await handleUpsertAccessory({
      data,
      onSuccess: () => {
        onClose();
      },
    });
  };

  return (
    <ModalComposer
      icon={<AddIcon className='icon' />}
      title='Edit Accessory'
      isOpen={isOpen}
      onClose={onClose}
    >
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <Stack
          spacing={2}
          sx={{
            mt: 2,
          }}
        >
          <KQNTextInputField
            control={control}
            label='Accessory (EN)'
            name='name'
            error={errors.name}
            fullWidth
          />
          <KQNTextInputField
            control={control}
            label='Accessory (DE)'
            name='nameDe'
            error={errors.nameDe}
            fullWidth
          />
          <ComposerFooterButtons onClose={onClose} isLoading={isLoading} />
        </Stack>
      </form>
    </ModalComposer>
  );
};

export default UpdateAccessoryModal;
