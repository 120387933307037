import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Paper, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { InfoRow, Optional } from '../../../components';
import { AccessoryModel } from '../../types';
import UpdateAccessoryModal from './update-accessory-modal';

type Props = {
  accessory: AccessoryModel;
};

const AccessoriesSummary: React.FC<Props> = ({ accessory }) => {
  const [openEdit, setOpenEdit] = useState(false);
  const { name, nameDe } = accessory;

  const handleOnEditClose = () => {
    setOpenEdit(false);
  };

  return (
    <Paper sx={{ minWidth: '500px', p: 3 }}>
      <Stack direction='column' gap={3} width='100%'>
        <Stack direction='row' justifyContent='space-between' alignItems='center'>
          <Typography>Accessory Profile</Typography>
          <EditOutlinedIcon
            className='icon'
            onClick={() => setOpenEdit(true)}
            sx={{ cursor: 'pointer' }}
          />
        </Stack>
        <Stack direction='column' gap={3} sx={{ color: 'kqn.darkGray' }}>
          <InfoRow label='Name (English)' value={name} />
          <InfoRow label='Name (German)' value={nameDe} />
        </Stack>
      </Stack>
      <Optional condition={openEdit}>
        <UpdateAccessoryModal accessory={accessory} isOpen={openEdit} onClose={handleOnEditClose} />
      </Optional>
    </Paper>
  );
};

export default AccessoriesSummary;
