import { Stack } from '@mui/material';
import { KQNTextField, TextInputAndormnet, Title } from '../../../../../../../components';
import { useRecipeActions, useRecipeStore } from '../../../../../../../store';
import NumberOfPeople from './number-of-people';

const IngredientsHeader: React.FC = () => {
  const { carbs, energy, fat, protein } = useRecipeStore.use.nutrients();
  const { updateNutrient } = useRecipeActions();

  return (
    <Stack
      direction='row'
      justifyContent='space-between'
      alignContent='center'
      alignItems='center'
      width='100%'
    >
      <Stack
        direction='row'
        gap={5}
        justifyContent='flex-start'
        sx={{ color: 'kqn.darkGray' }}
        width='100%'
      >
        <Stack direction='column' gap={1}>
          <Title title='Nutrients per serving, g' />
          <Stack direction='row' gap={2} width='100%'>
            <KQNTextField
              size='small'
              fullWidth
              value={carbs || ''}
              onChange={(e) => updateNutrient('carbs', parseFloat(e.target.value) || 0)}
              onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
              InputProps={{
                type: 'number',
                inputMode: 'numeric',
                inputProps: {
                  style: { textAlign: 'center', width: '80px', paddingRight: 0, paddingLeft: 0 },
                },
                startAdornment: <TextInputAndormnet position='start' text='Carbs' />,
              }}
            />
            <KQNTextField
              size='small'
              fullWidth
              value={protein || ''}
              onChange={(e) => updateNutrient('protein', parseFloat(e.target.value) || 0)}
              onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
              InputProps={{
                type: 'number',
                inputMode: 'numeric',
                inputProps: {
                  style: { textAlign: 'center', width: '80px', paddingRight: 0, paddingLeft: 0 },
                },
                startAdornment: <TextInputAndormnet position='start' text='Protein' />,
              }}
            />
            <KQNTextField
              size='small'
              fullWidth
              value={fat || ''}
              onChange={(e) => updateNutrient('fat', parseFloat(e.target.value) || 0)}
              onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
              InputProps={{
                type: 'number',
                inputMode: 'numeric',
                inputProps: {
                  style: { textAlign: 'center', width: '80px', paddingRight: 0, paddingLeft: 0 },
                },
                startAdornment: <TextInputAndormnet position='start' text='Fat' />,
              }}
            />
          </Stack>
        </Stack>
        <Stack direction='row' gap={5} width='60%'>
          <Stack direction='column' gap={1}>
            <Title title='Energy, kcal' />
            <KQNTextField
              size='small'
              fullWidth
              value={energy || ''}
              onChange={(e) => updateNutrient('energy', parseFloat(e.target.value) || 0)}
              onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
              InputProps={{
                type: 'number',
                inputMode: 'numeric',
                inputProps: {
                  style: {
                    textAlign: 'center',
                    width: '80px',
                    paddingRight: 0,
                    paddingLeft: 0,
                  },
                },
                startAdornment: <TextInputAndormnet position='start' text='Energy' />,
              }}
            />
          </Stack>
          <NumberOfPeople />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default IngredientsHeader;
