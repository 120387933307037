import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Box, Button, Stack } from '@mui/material';
import React, { useMemo } from 'react';
import { useComponentSize } from '../../../../shared/hooks';
import { RecipeBaseModel } from '../../../types';
import RecipeCard, { RECIPE_DEFAULT_WIDTH } from './recipe-card';

type Props = {
  recipes: RecipeBaseModel[];
  total: number;
  onShowAll: () => void;
  isExpanded?: boolean;
};

const RecipeLatest: React.FC<Props> = ({ recipes, total, onShowAll, isExpanded }) => {
  const { width, ref } = useComponentSize();
  const numRecipes = useMemo(() => Math.floor(width / RECIPE_DEFAULT_WIDTH), [width]);

  if (isExpanded) return null;

  return (
    <Box ref={ref} justifyContent='center' display='flex' flexDirection='column' gap={1}>
      <Stack direction='row' gap={1}>
        {recipes.slice(0, numRecipes > 10 ? 10 : numRecipes).map((recipe, idx) => (
          <RecipeCard key={recipe.id} recipe={recipe} />
        ))}
      </Stack>
      <Button
        sx={{ mx: 'auto', border: '0.5px solid #717174', borderRadius: '0 !important' }}
        onClick={onShowAll}
        startIcon={<KeyboardArrowDownIcon className='icon' />}
      >
        Show all recipes ({total})
      </Button>
    </Box>
  );
};

export default RecipeLatest;
