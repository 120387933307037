import { useEffect, useRef, useState } from 'react';

export const useComponentSize = () => {
  const ref = useRef<HTMLDivElement>(null);
  const [screenSize, setScreenSize] = useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    if (ref.current) {
      setScreenSize({
        width: ref.current?.offsetWidth || 1000,
        height: ref.current?.offsetHeight || 0,
      });
    }

    const handleResize = () => {
      setScreenSize({
        width: ref.current?.offsetWidth || 1000,
        height: ref.current?.offsetHeight || 0,
      });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [ref]);

  return {
    ref,
    ...screenSize,
  };
};
