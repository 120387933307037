import { Box } from '@mui/material';
import React from 'react';

type Props = {
  children: React.ReactNode;
};

const MainLayout: React.FC<Props> = ({ children }) => {
  return <Box sx={{ px: 5.5, pt: 2, pb: 1 }}>{children}</Box>;
};

export default MainLayout;
