import { Grid, Stack } from '@mui/material';
import { MainLayout } from '../../../../../../../components';
import { AccessoriesPicker } from '../../../../../../accessories';
import { GroupsComposer } from './groups';
import IngredientsHeader from './ingredients-header';

interface Props {
  recipeId: string | null;
}

const IngredientsComposer: React.FC<Props> = ({ recipeId }) => {
  if (!recipeId) return null;

  return (
    <MainLayout>
      <Stack direction='column' gap={3} width='100%'>
        <Grid container columns={14} spacing={1}>
          <Grid item xs={14} md={14} lg={12} xl={7}>
            <IngredientsHeader />
          </Grid>
          <Grid item xs={14} md={14} lg={12} xl={7}>
            <AccessoriesPicker />
          </Grid>
        </Grid>
        <GroupsComposer recipeId={recipeId} />
      </Stack>
    </MainLayout>
  );
};

export default IngredientsComposer;
