import { Stack } from '@mui/material';
import { GridIcon, ListIcon } from '../../../../shared';

type Props = {
  selectedView?: 'table' | 'grid';
  handleView: (view: 'table' | 'grid') => void;
};

const ViewSwitch: React.FC<Props> = ({ handleView, selectedView }) => {
  return (
    <Stack
      direction='row'
      gap={1.5}
      px={1}
      sx={{
        borderRadius: '2px',
        ':hover': { bgcolor: 'kqn.gray' },
        transition: 'all 0.2s ease-in-out',
      }}
    >
      <ListIcon
        style={{
          cursor: 'pointer',
          background: selectedView === 'table' ? '#EAEAEA' : '',
          borderRadius: '2px',
        }}
        data-testid='list-view-button'
        onClick={() => handleView('table')}
      />
      <GridIcon
        style={{
          cursor: 'pointer',
          background: selectedView === 'grid' ? '#EAEAEA' : '',
          borderRadius: '2px',
        }}
        data-testid='grid-view-button'
        onClick={() => handleView('grid')}
      />
    </Stack>
  );
};

export default ViewSwitch;
