import { rectIntersection } from '@dnd-kit/core';
import { horizontalListSortingStrategy } from '@dnd-kit/sortable';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Box, IconButton, Stack } from '@mui/material';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { DragNDropContext } from '../../../../../../../../../../components';
import { useHandleDragEnd, useScrollIntoView } from '../../../../../../../../../../shared';
import { useMealActions } from '../../../../../../../../../../store/meal-store';
import { MethodType, Notification, Trigger } from '../../../../../../../../../types';
import { createNotification } from './helpers';
import { useRemoveNotification, useUpdateNotificationsOrdinals } from './mutations';
import NotificationTab from './notification-tab';
import PresetNotification from './preset-notification';

type Props = {
  mealId: string;
  presetId: string;
  method?: MethodType;
  isDisabled?: boolean;
  triggers: Trigger[];
  stepNotifications?: Notification[];
};

const Notifications: React.FC<Props> = ({
  mealId,
  presetId,
  stepNotifications,
  triggers,
  isDisabled,
  method,
}) => {
  const { updateNotifications } = useMealActions();
  const [notifications, setNotifications] = useState<Notification[]>(stepNotifications || []);
  const [activeNotificationId, setActiveNotificationId] = useState<string | undefined>(undefined);
  const { lockDurations } = useMealActions();
  const { handleOnRemove } = useRemoveNotification();
  const { mutation: ordinalMutation } = useUpdateNotificationsOrdinals();
  const { ref, scrollIntoView } = useScrollIntoView({ behavior: 'smooth', block: 'center' });
  const hasDraft = useMemo(() => notifications?.some((n) => n.isDraft), [notifications]);

  useEffect(() => {
    if (notifications.length < 1) {
      lockDurations(mealId, presetId, false);
      return;
    }

    lockDurations(mealId, presetId, true);
    updateNotifications(mealId, presetId, notifications);
  }, [lockDurations, mealId, notifications, notifications.length, presetId, updateNotifications]);

  const handleUpdateNotification = useCallback(
    (oldNotificationId: string, notification: Notification) => {
      setNotifications((prev) =>
        prev.map((n) => (n.id === oldNotificationId ? { ...notification, isDraft: false } : n)),
      );
      setActiveNotificationId(notification.id);
      if (notifications.length > 0) {
        lockDurations(mealId, presetId, true);
      } else {
        lockDurations(mealId, presetId, false);
      }
    },
    [lockDurations, mealId, notifications.length, presetId],
  );

  const handleDuplicateNotification = useCallback((notification: Notification) => {
    setNotifications((prev) => [...prev, { ...notification, isDraft: false }]);
  }, []);

  useEffect(() => {
    if (hasDraft) {
      scrollIntoView();
    }
  }, [hasDraft, scrollIntoView]);

  const handleClick = useCallback((notificationId: string) => {
    setActiveNotificationId((prev) => (prev === notificationId ? undefined : notificationId));
  }, []);

  const handleAddNotification = () => {
    const newNotification = createNotification(notifications, triggers);
    setNotifications((prev) => [...prev, newNotification]);
    setActiveNotificationId(newNotification.id);
  };

  const handleRemoveNotification = async (notificationId: string) => {
    const notification = notifications?.find((n) => n.id === notificationId);
    const filtered = notifications.filter((n) => n.id !== notificationId);

    if (!notification?.isDraft) {
      await handleOnRemove({ mealId, stepId: presetId, notificationId });
    }

    setNotifications(filtered);
    updateNotifications(mealId, presetId, filtered);
    if (filtered.length < 1) {
      lockDurations(mealId, presetId, false);
    }
  };

  const handleOnUpdate = (updated: Notification[]) => {
    setNotifications(updated);
    ordinalMutation.mutate({ notifications: updated });
  };

  const handleDragEnd = useHandleDragEnd<Notification>({
    data: notifications || [],
    onUpdate: handleOnUpdate,
  });

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Stack direction='column'>
        <Stack direction='row' alignItems='center' width='100%'>
          {!isDisabled && (
            <IconButton onClick={handleAddNotification} disabled={hasDraft}>
              <AddCircleOutlineIcon
                sx={{
                  color: 'kqn.darkGray',
                  cursor: 'pointer',
                  '&:hover': { color: 'kqn.cooper' },
                }}
              />
            </IconButton>
          )}

          <Box
            display='flex'
            justifyContent='flex-start'
            gap={1}
            flexDirection='row'
            sx={{ overflow: 'hidden', maxWidth: '100%' }}
          >
            <DragNDropContext
              items={notifications}
              isDisabled={hasDraft}
              onDragEnd={handleDragEnd}
              collisionDetection={rectIntersection}
              strategy={horizontalListSortingStrategy}
            >
              {notifications?.map((notification, idx) => {
                return (
                  <div key={notification.id}>
                    <NotificationTab
                      mealId={mealId}
                      idx={idx}
                      stepId={presetId}
                      isDraft={notification.isDraft}
                      onClick={handleClick}
                      notificationId={notification.id}
                      onRemoveNotification={handleRemoveNotification}
                      isActive={notification.id === activeNotificationId}
                      onDuplicateNotification={handleDuplicateNotification}
                    />
                    <div ref={ref} />
                  </div>
                );
              })}
            </DragNDropContext>
          </Box>
        </Stack>
        {activeNotificationId && (
          <PresetNotification
            mealId={mealId}
            method={method}
            stepId={presetId}
            onUpdate={handleUpdateNotification}
            notification={notifications?.find((n) => n.id === activeNotificationId)}
          />
        )}
      </Stack>
    </Box>
  );
};

export default memo(Notifications);
