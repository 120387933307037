import { Box, Button, Stack } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  AssociatedRecipes,
  DetailsTableSkeleton,
  MainLayout,
  Navigation,
  Optional,
  TableStyled,
  TableWrapper,
} from '../../../components';
import { NavigationPaths } from '../../../constants';
import { queries } from '../../queries';
import { DeleteIngredientModal, IngredientSummary } from '../components';

const IngredientDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [openDelete, setOpenDelete] = useState(false);

  const { data, isLoading, isFetching } = useQuery({
    ...queries.ingredients.details(id),
    enabled: !!id,
  });

  if (isLoading || isFetching) return <DetailsTableSkeleton title={data?.name} />;

  if (!data) return <div>Ingredient not found</div>;

  return (
    <TableWrapper>
      <TableStyled>
        <Box sx={{ width: '100%' }}>
          <Navigation pageTitle={data.name}>
            <Stack direction='row' justifyContent='flex-end' width='100%'>
              <Button variant='outlined' color='error' onClick={() => setOpenDelete(true)}>
                DELETE
              </Button>
            </Stack>
          </Navigation>
          <MainLayout>
            <Stack direction='row' gap={4} justifyContent='flex-start'>
              <IngredientSummary ingredient={data} />
              <AssociatedRecipes recipeIds={data.groups} />
            </Stack>
          </MainLayout>
        </Box>
      </TableStyled>
      <Optional condition={openDelete}>
        <DeleteIngredientModal
          ingredients={[data]}
          isOpen={openDelete}
          onClose={() => setOpenDelete(false)}
          onSucess={() => {
            navigate(NavigationPaths.INGREDIENTS);
          }}
        />
      </Optional>
    </TableWrapper>
  );
};

export default IngredientDetails;
