import { useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { koqoonApi } from '../../../config';
import { Paths } from '../../../constants';
import { useAuthentication } from '../../../context';
import { queries } from '../../../modules/queries';
import { UserUpdateSchemaType } from '../schema';

interface Props {
  userId: string;
  onSuccess?: () => void;
}

export const useUpdateUserAccount = ({ userId, onSuccess }: Props) => {
  const queryClient = useQueryClient();
  const { user } = useAuthentication();

  const path =
    user?.id === userId
      ? `${Paths.USERS}/update-account`
      : `${Paths.USERS}/${userId}/update-user-account`;

  const mutation = useMutation(({ data }: { data: UserUpdateSchemaType }) =>
    koqoonApi.put(path, data),
  );

  const handleUpdateUserAccount = async (data: UserUpdateSchemaType) => {
    mutation.mutate(
      { data },
      {
        onSuccess: () => {
          toast.success('Users account updated successfully');
          queryClient.refetchQueries(queries.users.filter._def);
          queryClient.refetchQueries(queries.users.details._def);
          onSuccess?.();
        },
        onError: (e: any) => {
          toast.error(e.response.data.message);
        },
      },
    );
  };

  return { handleUpdateUserAccount, isLoading: mutation.isLoading };
};
