import { Box, Button, Stack } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  DetailsTableSkeleton,
  MainLayout,
  Navigation,
  Optional,
  TableStyled,
  TableWrapper,
} from '../../../components';
import { NavigationPaths } from '../../../constants';
import { queries } from '../../queries';
import { RemoveUnitModal, UnitSummary } from '../components';

const UnitDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [openDelete, setOpenDelete] = useState(false);

  const { data, isLoading, isFetching } = useQuery({
    ...queries.units.details(id),
    enabled: !!id,
  });

  if (isLoading || isFetching) return <DetailsTableSkeleton type='single' title={data?.name} />;

  if (!data || !id) return <div>Unit not found</div>;

  return (
    <TableWrapper>
      <TableStyled>
        <Box sx={{ width: '100%' }}>
          <Navigation pageTitle={data.name}>
            <Stack direction='row' justifyContent='flex-end' width='100%'>
              <Button variant='outlined' color='error' onClick={() => setOpenDelete(true)}>
                Delete
              </Button>
            </Stack>
          </Navigation>
          <MainLayout>
            <UnitSummary unit={data} />
          </MainLayout>
        </Box>
      </TableStyled>
      <Optional condition={openDelete}>
        <RemoveUnitModal
          selected={data}
          isOpen={openDelete}
          onClose={() => setOpenDelete(false)}
          onSucess={() => {
            navigate(NavigationPaths.UNITS);
          }}
        />
      </Optional>
    </TableWrapper>
  );
};

export default UnitDetails;
