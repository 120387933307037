import { Button, CircularProgress, FormControlLabel, Stack, Switch } from '@mui/material';
import { useState } from 'react';
import { Optional } from '../../../../../../components';
import { useAuthentication } from '../../../../../../context';
import { CopyIcon } from '../../../../../../shared';
import { useRecipeActions } from '../../../../../../store';
import { RecipeBaseModel } from '../../../../../types';
import { useRecipePublishing } from '../../../../mutations';
import ExportRecipe from '../../../export-recipe/export-recipe';
import { DuplicateRecipeModal } from '../../../modals';
import { useHeaderConfig } from '../hooks/use-header-config';

type Props = {
  recipe?: RecipeBaseModel;
  hasUnsavedChanges: boolean;
  value: number;
  isLoading?: boolean;
  onSave?: () => void;
  onDiscard?: () => void;
};

const HeaderActions: React.FC<Props> = ({
  value,
  recipe,
  isLoading,
  onSave,
  hasUnsavedChanges,
  onDiscard,
}) => {
  const { updatePublished } = useRecipeActions();
  const { handlePublish } = useRecipePublishing();
  const [openDuplicate, setOpenDuplicate] = useState(false);
  const { user: authUser } = useAuthentication();
  const isTester = authUser?.role === 'TESTER';
  const isEdit = !!recipe?.id;
  const { duplicate, globalSave, publish } = useHeaderConfig(value);

  const handleSwitchChange = async () => {
    if (!recipe?.id) return;

    await handlePublish(recipe.id, updatePublished);
  };

  const handleDuplicate = () => {
    setOpenDuplicate(true);
  };

  if (!recipe) return null;

  return (
    <Stack gap={1} direction='row' alignItems='center'>
      {isEdit && !isTester && (
        <>
          {publish && (
            <FormControlLabel
              control={
                <Switch
                  size='medium'
                  checked={recipe.isPublished}
                  color='primary'
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                  onChange={handleSwitchChange}
                  sx={{
                    '& .MuiSwitch-switchBase.Mui-checked': { color: 'kqn.cooper' },

                    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                      bgcolor: 'kqn.lightCooper',
                    },
                  }}
                />
              }
              sx={{
                '.MuiFormControlLabel-label': { color: 'kqn.darkGray', fontSize: '12px' },
              }}
              label={recipe.isPublished ? 'PUBLISHED' : 'UNPUBLISHED'}
            />
          )}
          {recipe.id && <ExportRecipe recipeId={recipe.id} />}
          {duplicate && (
            <Button
              data-testid='duplicate-button'
              onClick={handleDuplicate}
              startIcon={<CopyIcon />}
              sx={{ fontSize: '12px', color: 'kqn.darkGray' }}
            >
              DUPLICATE
            </Button>
          )}
        </>
      )}
      {globalSave && (
        <Button
          disabled={!hasUnsavedChanges}
          variant='contained'
          color='secondary'
          onClick={onDiscard}
          sx={{
            display: hasUnsavedChanges ? 'block' : 'none',
            width: 'fit-content',
            height: 'fit-content',
          }}
        >
          DISCARD
        </Button>
      )}
      {globalSave && (
        <Button
          variant='contained'
          color='primary'
          data-testid='save-button'
          disabled={!hasUnsavedChanges || isLoading}
          onClick={onSave}
          sx={{
            width: 'fit-content',
            height: 'fit-content',
            color: 'kqn.white',
            '&:hover': { bgcolor: 'kqn.darkerGray' },
          }}
          startIcon={isLoading && <CircularProgress size={15} sx={{ color: 'kqn.cooper' }} />}
        >
          SAVE
        </Button>
      )}
      <Optional condition={openDuplicate}>
        <DuplicateRecipeModal
          recipe={recipe}
          isOpen={openDuplicate}
          onClose={() => setOpenDuplicate(false)}
        />
      </Optional>
    </Stack>
  );
};

export default HeaderActions;
