import { Icon } from '@iconify/react';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Stack, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { DashboardIcon } from '../../shared/assets';
import { usePathname } from '../../shared/hooks';
import { Optional } from '../optional';

interface Props {
  pageTitle?: string;
  children?: React.ReactNode;
}

const Navigation: React.FC<Props> = ({ pageTitle, children }) => {
  const { route } = usePathname();

  return (
    <Stack
      direction='column'
      justifyContent='space-between'
      alignItems='flex-start'
      sx={{
        minHeight: '100px',
        pt: 1,
        pb: 1,
        gap: 1,
        px: 5.5,
        bgcolor: 'kqn.gray',
        color: 'kqn.darkGray',
      }}
    >
      <Stack direction='row' gap={1} alignItems='center'>
        <DashboardIcon />
        <Link to='/'>
          <Typography variant='body2'>Dashoard</Typography>
        </Link>
        {route.map(({ icon, pathname, title }) => (
          <Stack justifyContent='center' gap={1} direction='row' key={title} alignItems='center'>
            <NavigateNextIcon />
            {icon && <Icon icon={icon || ''} className='icon' />}
            {pathname ? (
              <Link to={pathname || ''}>
                <Typography variant='body2'>{title}</Typography>
              </Link>
            ) : (
              <Typography variant='body2'>{title}</Typography>
            )}
          </Stack>
        ))}
      </Stack>
      <Optional condition={pageTitle}>
        <Typography variant='h5' sx={{ color: '#000' }}>
          {pageTitle}
        </Typography>
      </Optional>
      {children}
    </Stack>
  );
};

export default Navigation;
