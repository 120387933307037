import { zodResolver } from '@hookform/resolvers/zod';
import AddIcon from '@mui/icons-material/Add';
import { Box, Divider, FormControl, MenuItem, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useQuery } from '@tanstack/react-query';
import { Controller, useForm } from 'react-hook-form';
import {
  ComposerFooterButtons,
  KQNTextField,
  KQNTextInputField,
  ModalPopup,
} from '../../../../components';
import { queries } from '../../../queries';
import { useCreateUser } from '../../mutations';
import { createUserSchema, UserSchemaType } from './schema';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const CreateUserModal: React.FC<Props> = ({ isOpen, onClose }) => {
  const { handleCreateUser, isLoading: isSubmitting } = useCreateUser();
  const { control, handleSubmit } = useForm<UserSchemaType>({
    resolver: zodResolver(createUserSchema),
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      role: 'CONTENT MANAGER',
    },
  });

  const { data: roles, isLoading } = useQuery({
    ...queries.users.roles(),
    staleTime: Infinity,
    cacheTime: Infinity,
  });

  const handleOnSubmit = async (data: UserSchemaType) => {
    await handleCreateUser({ data, onSuccess: onClose });
  };

  return (
    <ModalPopup isOpen={isOpen} onClose={onClose}>
      <Box border={0} sx={{ width: '600px', bgcolor: 'background.paper', p: 3, boxShadow: 24 }}>
        <Stack
          gap={2}
          sx={{ color: 'kqn.cooper' }}
          pb={2}
          direction='row'
          alignItems='center'
          justifyContent='flex-start'
        >
          <AddIcon className='icon' />
          <Typography variant='h6'>ADD USER</Typography>
        </Stack>
        <Divider sx={{ borderColor: 'kqn.cooper' }} />
        <form onSubmit={handleSubmit(handleOnSubmit)}>
          <Stack pt={3} gap={3}>
            <Stack
              direction='row'
              justifyContent='space-between'
              gap={2}
              alignContent='center'
              alignItems='center'
            >
              <KQNTextInputField
                label='First Name'
                control={control}
                placeholder='First Name'
                name='firstName'
              />
              <KQNTextInputField
                label='Last Name'
                control={control}
                name='lastName'
                placeholder='Last Name'
                data-testid='last-name'
              />
            </Stack>
            <Stack
              direction='row'
              justifyContent='space-between'
              gap={2}
              alignContent='center'
              alignItems='center'
            >
              <KQNTextInputField
                label='Email'
                type='email'
                placeholder='test@koqoon.com'
                data-testid='email'
                control={control}
                name='email'
              />
              <KQNTextInputField
                control={control}
                name='password'
                label='Password'
                type='password'
                placeholder='********'
                data-testid='password'
              />
            </Stack>
            <Stack
              direction='row'
              justifyContent='space-between'
              gap={2}
              alignContent='center'
              alignItems='center'
            >
              <Controller
                disabled={isLoading}
                name='role'
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <FormControl fullWidth>
                    <KQNTextField
                      data-testid='role-select'
                      size='small'
                      select
                      variant='outlined'
                      label='Role'
                      inputRef={ref}
                      {...field}
                    >
                      {roles?.map((role) => (
                        <MenuItem key={role.id} value={role.name}>
                          {role.name}
                        </MenuItem>
                      ))}
                    </KQNTextField>
                  </FormControl>
                )}
              />
              <Box width='100%' />
            </Stack>
            <ComposerFooterButtons onClose={onClose} isLoading={isSubmitting} />
          </Stack>
        </form>
      </Box>
    </ModalPopup>
  );
};

export default CreateUserModal;
