import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { routes } from '../config';

export const usePathname = () => {
  const { pathname } = useLocation();

  const paths = pathname.split('/').filter((path) => path);

  const route = useMemo(() => routes.filter((r) => paths.includes(r.path)), [paths]);

  return {
    paths,
    route,
  };
};
