import { Box, Paper, Table, TableCell, TableContainer, TableRow } from '@mui/material';
import { useEffect } from 'react';
import {
  EnhancedTableHead,
  MainLayout,
  Navigation,
  Pagination,
  TableStyled,
  TableWrapper,
  useTableClick,
  useTableSort,
} from '../../../components';
import { PaginatedResult } from '../../../types';
import { AccessoryHeadCells, AccessoryModel } from '../../types';
import AccessoriesEnhancedTableActions from './accessories-enhanced-table-actions';
import AccessoriesEnhancedTableBody from './accessories-enhanced-table-body';
import accessoriesHeadCells from './accessory-head-cells';

interface Props {
  data: PaginatedResult<AccessoryModel>;
  onPageChange: (event: unknown, newPage: number) => void;
  onRowsPerPageChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const AccessoriesEnhancedTable: React.FC<Props> = ({ data, onPageChange, onRowsPerPageChange }) => {
  const { data: accessories, meta } = data;
  const { selected, setSelected, handleClick } = useTableClick();
  const { handleRequestSort, order, orderBy } = useTableSort<AccessoryHeadCells>();
  const { currentPage, perPage } = meta;
  const page = currentPage > 0 ? currentPage - 1 : 0;

  useEffect(() => {
    setSelected([]);
  }, [accessories, setSelected]);

  return (
    <TableWrapper>
      <TableStyled>
        <Box sx={{ width: '100%' }}>
          <Navigation pageTitle='Accessories'>
            <AccessoriesEnhancedTableActions
              accessory={data.data.find((ac) => ac.id === selected[0])}
              selected={selected}
            />
          </Navigation>
          <MainLayout>
            <Paper sx={{ width: '50%', mb: 2 }}>
              <TableContainer
                sx={{ maxHeight: 'calc(100vh - 350px)', overflow: 'auto', borderRadius: '2px' }}
              >
                <Table sx={{ minWidth: 750 }} aria-labelledby='tableTitle' size='medium'>
                  <EnhancedTableHead
                    headCells={accessoriesHeadCells}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                  >
                    <TableRow>
                      <TableCell align='center' colSpan={3} sx={{ fontWeight: 'bold' }}>
                        ACCESSORY NAME
                      </TableCell>
                      <TableCell
                        align='center'
                        colSpan={3}
                        sx={{ '& .MuiTableCell-root': { borderBottom: 'none' } }}
                      />
                    </TableRow>
                  </EnhancedTableHead>
                  <AccessoriesEnhancedTableBody
                    data={data}
                    page={page}
                    perPage={perPage}
                    order={order}
                    orderBy={orderBy}
                    handleOnSelect={handleClick}
                    selected={selected}
                  />
                </Table>
              </TableContainer>
              <Pagination
                total={data.meta.total}
                perPage={perPage}
                page={page}
                onPageChange={onPageChange}
                onRowsPerPageChange={onRowsPerPageChange}
              />
            </Paper>
          </MainLayout>
        </Box>
      </TableStyled>
    </TableWrapper>
  );
};

export default AccessoriesEnhancedTable;
