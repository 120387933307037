import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSearch } from '../../../../context';
import { fetchConfig, useDebounceValue } from '../../../../shared';
import { queries } from '../../../queries';
import { mapUnitsResponseToObject } from '../../mappers/map-unit-response-to-object';
import UnitEnhancedTable from './unit-enhanced-table';

const UnitTable = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const pageParam = parseInt(searchParams.get('page') || '1', 10);
  const perPageParam = parseInt(searchParams.get('perPage') || '50', 10);
  const { query } = useSearch();
  const debouncedQuery = useDebounceValue(query);

  const { data, isLoading, isFetching } = useQuery({
    ...queries.units.filter({
      query: debouncedQuery,
      page: pageParam,
      perPage: perPageParam,
    }),
    keepPreviousData: true,
    refetchOnMount: true,
    refetchOnReconnect: true,
    suspense: true,
  });

  useEffect(() => {
    if (debouncedQuery === '') return;

    setSearchParams({ page: String(1), perPage: perPageParam.toString() });
  }, [debouncedQuery, perPageParam, setSearchParams]);

  if (!data) return null;

  const units = mapUnitsResponseToObject(data);

  const handleChangePage = (_: unknown, newPage: number) => {
    setSearchParams({ page: String(newPage + 1), perPage: perPageParam.toString() });
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (fetchConfig.rowsPerPageOptions.includes(Number(event.target.value))) {
      setSearchParams({ page: '1', perPage: event.target.value });
      return;
    }

    setSearchParams({ page: '1', perPage: '50' });
  };
  return (
    <UnitEnhancedTable
      data={units}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  );
};

export default UnitTable;
