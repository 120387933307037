import { alpha, InputBase, styled, SxProps, Theme } from '@mui/material';
import MuiTextField from '@mui/material/TextField';
import { HTMLInputTypeAttribute } from 'react';
import { Control, Controller } from 'react-hook-form';

type TextFieldProps = {
  height?: string;
  borderColor?: string;
  focusBorderColor?: string;
};

const options = {
  shouldForwardProp: (prop: any) => prop !== 'borderColor',
};
const outlinedSelectors = [
  '& .MuiOutlinedInput-notchedOutline',
  '&:hover .MuiOutlinedInput-notchedOutline',
  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline',
  '& .MuiInputBase-root-MuiInput-root:after',
];

export const KQNTextField = styled(
  MuiTextField,
  options,
)<TextFieldProps>(({ borderColor = '#717174', focusBorderColor, height }) => ({
  '& .MuiInputBase-root': {
    height,
  },

  '& .MuiInputBase-input': {
    paddingLeft: '8px',
  },

  '& label.Mui-focused': {
    color: focusBorderColor || borderColor,
  },
  [outlinedSelectors.join(',')]: {
    borderColor,
  },

  '& label': {
    color: borderColor,
  },

  '& .MuiOutlinedInput-root': {
    padding: '0',
    borderRadius: '2px',

    ' &.Mui-focused fieldset:': {
      borderColor,
    },
  },
}));

interface Props extends TextFieldProps {
  name: string;
  control?: Control<any>;
  label?: string;
  placeholder?: string;
  sx?: SxProps<Theme>;
  type?: HTMLInputTypeAttribute;
  [x: string]: any;
}

const KQNTextInputField: React.FC<Props> = ({
  name,
  control,
  label,
  placeholder,
  sx,
  borderColor = '#717174',
  height,
  type = 'text',
  ...rest
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <KQNTextField
          borderColor={borderColor}
          height={height}
          helperText={error ? error.message : ''}
          size='small'
          type={type}
          error={!!error}
          onChange={onChange}
          value={value}
          fullWidth
          label={label}
          onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
          InputProps={{
            placeholder,
          }}
          sx={sx}
          {...rest}
        />
      )}
    />
  );
};

export const KQNBaseInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: '#fff',
    border: '1px solid',
    borderColor: theme.palette.mode === 'light' ? '#E0E3E7' : '#2D3843',
    fontSize: 16,
    width: '100%',
    padding: '10px 12px',
    transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow']),
    '&:focus': {
      boxShadow: `${alpha('#AF6B48', 0.25)} 0 0 0 0.2rem`,
      borderColor: '#AF6B48',
    },
  },
}));

export default KQNTextInputField;
