import { ConfirmModal } from '../../../../components';
import { useRemoveRecipe } from '../../mutations';

interface Props {
  isOpen: boolean;
  onClose: (event?: any) => void;
  recipeId?: string;
}

const DeleteRecipeModal: React.FC<Props> = ({ isOpen, onClose, recipeId }) => {
  const { handleRemoveRecipe } = useRemoveRecipe();

  const handleDelete = async (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (!recipeId) return;
    event?.stopPropagation();

    await handleRemoveRecipe({ recipeId, onSuccess: onClose });
  };

  return (
    <ConfirmModal
      isOpen={isOpen}
      onClose={onClose}
      onCofirm={handleDelete}
      title='Are you sure you want to delete this recipe?'
    />
  );
};

export default DeleteRecipeModal;
