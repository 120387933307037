import { Box, Button, Stack } from '@mui/material';
import { createId } from '@paralleldrive/cuid2';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import toast from 'react-hot-toast';
import { koqoonApi } from '../../config';
import { queries } from '../../modules/queries';
import { attachmentConfig } from '../../shared/config';
import { useRecipeStore } from '../../store';
import { ImageFile } from '../../types';
import { DropzoneArea, ImageIndicatorInfo, ImagePreview } from './components';

interface Props {
  recipeId: string | null;
  onUpload: (imageUrl: string) => void;
  onRemove: () => void;
  imageUri: string;
}

const Dropzone: React.FC<Props> = ({ recipeId, imageUri, onRemove, onUpload }) => {
  const queryClient = useQueryClient();
  const { setFile } = useRecipeStore.use.recipeActions();
  const [files, setFiles] = useState<ImageFile[]>([]);
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [status, setStatus] = useState<'preview' | 'loading' | 'completed' | 'failed'>();
  const hasImage = !!imageUri || !!files.length;
  const canDelete = !!imageUri || status === 'completed';
  const hasFileChanged = files?.[0]?.preview;

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      setStatus('preview');
      if (acceptedFiles.length) {
        setFiles((prevFiles) => [
          ...prevFiles,
          ...acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
              title: `${file.name}_${Date.now()}`,
            }),
          ),
        ]);
      }
      setFile(acceptedFiles[0]);
    },
    [setFile],
  );

  const handleCancel = () => {
    setFiles([]);
  };

  const handleUpload = async () => {
    if (!files) return;
    if (!recipeId) return;

    setUploading(true);
    setStatus('loading');
    try {
      const formData = new FormData();
      const file = files[0];
      /**
       * This is needed in order to have a unique name for the image
       * otherwise, the same images will not be uploaded, just reused.
       * So, if we delete one image, all recipes that use that image will
       * be affected.
       */
      const uniqueNameId = createId();

      formData.append('file', file, uniqueNameId);
      formData.append('recipeId', recipeId);

      const response = await koqoonApi.post(`recipes/${recipeId}/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          const percent = Math.floor((loaded * 100) / (total || 0));
          setProgress(percent);
        },
      });

      if (response.status === 201) {
        setStatus('completed');
        const { data } = response;
        if (data && data.imageUrl) {
          onUpload(data.imageUrl);
          queryClient.refetchQueries(queries.recipes.filter._def);
          queryClient.refetchQueries(queries.recipes.details._def);
          queryClient.refetchQueries(queries.recipes.infinityFilter._def);
          toast.success('Image uploaded successfully');
        }
        return;
      }

      setStatus('failed');
    } catch (err: any) {
      setStatus('failed');
    } finally {
      setUploading(false);
    }
  };

  const handleRemove = () => {
    setFiles([]);
    onRemove();
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: { 'image/jpeg': attachmentConfig.allowedFileTypes },
    maxFiles: attachmentConfig.maxFiles,
    maxSize: attachmentConfig.maxFileSize,
    multiple: false,
    onDropRejected: () => {
      toast.error('Invalid file type or size');
    },
  });

  return (
    <Box width='100%' data-testid='dropzone'>
      <Stack direction='column' justifyContent='center' alignItems='center'>
        <Box gap={2} display='flex' flexDirection='column' width='100%'>
          <ImagePreview file={files[0]} imageUrl={imageUri} />
          <DropzoneArea
            acceptedExtensions={['JPG', 'JPEG', 'PNG']}
            getInputProps={getInputProps}
            getRootProps={getRootProps}
            isDragActive={isDragActive}
          />
          {hasImage && !!recipeId && (
            <Stack direction='row' justifyContent='space-between' gap={2} width='100%'>
              <Box>
                {canDelete && (
                  <Button
                    type='button'
                    onClick={handleRemove}
                    variant='outlined'
                    color='error'
                    sx={{ borderRadius: '8px' }}
                  >
                    REMOVE
                  </Button>
                )}
              </Box>
              <Box>
                {hasImage && (
                  <Button
                    type='button'
                    onClick={handleCancel}
                    variant='text'
                    sx={{ color: 'kqn.darkerGray', '&:hover': { color: 'kqn.darkerGray' } }}
                  >
                    CANCEL
                  </Button>
                )}
                <Button
                  type='button'
                  onClick={handleUpload}
                  variant='contained'
                  sx={{
                    backgroundColor: 'kqn.green',
                    color: 'kqn.white',
                    '&:hover': { backgroundColor: 'kqn.green' },
                  }}
                  disabled={uploading || status === 'completed' || !hasFileChanged || !recipeId}
                >
                  SAVE IMAGE
                </Button>
              </Box>
            </Stack>
          )}
        </Box>
      </Stack>
      <ImageIndicatorInfo
        file={files[0]}
        progress={progress}
        status={status}
        onCancel={handleCancel}
        imageUrl={imageUri}
      />
    </Box>
  );
};

export default Dropzone;
