import { Stack } from '@mui/material';
import { Title } from '../../../../../../../../../components';
import { InputView } from '../../../../../../../../../shared/components';
import { Duration } from '../../../../../../../../types';
import { burnersSortingOrders } from '../../../../../../../helpers';
import { shorterBurnerName } from '../../preset/components';

type Props = {
  title: string;
  durations?: Duration[];
};

const BurnerTimerView: React.FC<Props> = ({ title, durations }) => {
  return (
    <Stack direction='row' gap={2} sx={{ width: '100%' }} justifyContent='start'>
      <Stack direction='column' gap={1}>
        <Title title={title} />
        <Stack direction='row' gap={2} width='100%'>
          {durations
            ?.slice()
            ?.sort(
              (a, b) =>
                burnersSortingOrders[a.name as keyof typeof burnersSortingOrders] -
                burnersSortingOrders[b.name as keyof typeof burnersSortingOrders],
            )
            .map((duration) => (
              <InputView
                key={duration.id}
                value={String(duration.duration)}
                adornment={shorterBurnerName(duration.name as keyof typeof shorterBurnerName)}
              />
            ))}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default BurnerTimerView;
