import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Button, Menu, SxProps } from '@mui/material';
import { Theme } from '@mui/system';
import React from 'react';

interface Props {
  label?: string;
  disabled?: boolean;
  sx?: SxProps<Theme>;
  startIcon?: React.ReactNode;
  children: React.ReactNode;
}

const Dropdown: React.FC<Props> = ({ children, sx, disabled, label = 'Open', startIcon }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isOpen = Boolean(anchorEl);

  const handleOnClose = () => {
    setAnchorEl(null);
  };

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <Button
        disabled={disabled}
        id='action-btn'
        sx={{ ...sx, height: '40px', borderRadius: 2, px: 2 }}
        variant='contained'
        onClick={handleOpenMenu}
        aria-controls={isOpen ? 'action-menu' : undefined}
        aria-expanded={isOpen ? 'true' : undefined}
        aria-haspopup='true'
        startIcon={startIcon}
        endIcon={isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      >
        {label}
      </Button>
      {!disabled && (
        <Menu
          open={isOpen}
          id='action-menu'
          anchorEl={anchorEl}
          MenuListProps={{ 'aria-labelledby': 'action-btn' }}
          onClose={handleOnClose}
          onClick={handleOnClose}
        >
          {children}
        </Menu>
      )}
    </>
  );
};

export default Dropdown;
