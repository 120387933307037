import { z } from 'zod';

const mealTypeSchema = z.object({
  name: z.string(),
  description: z.string(),
  nameDe: z.string(),
  descriptionDe: z.string(),
});

const ingredientSchema = z.object({
  id: z.string().optional(),
  name: z.string(),
  nameDe: z.string(),
  quantity: z.number(),
  ordinal: z.number(),
  nutrients: z.array(
    z.object({
      amount: z.number(),
      name: z.string(),
      nameDe: z.string(),
    }),
  ),
  units: z.array(
    z.object({
      id: z.string().optional(),
      name: z.string(),
      namePlural: z.string(),
      nameDe: z.string(),
      nameDePlural: z.string(),
    }),
  ),
  unit: z.object({
    id: z.string().optional(),
    name: z.string(),
    namePlural: z.string(),
    nameDe: z.string(),
    nameDePlural: z.string(),
  }),
});

const preparationSchema = z.object({
  description: z.string(),
  descriptionDe: z.string(),
  ordinal: z.number(),
  ingredients: z
    .array(
      z.object({ id: z.string().optional(), ingredientName: z.string(), groupName: z.string() }),
    )
    .optional(),
});

const triggerSchema = z.object({
  name: z.string(),
  type: z.string(),
  value: z.number(),
});

const notificationSchema = z.object({
  message: z.string(),
  inAppMessage: z.string(),
  messageDe: z.string(),
  inAppMessageDe: z.string(),
  ordinal: z.number(),
  triggers: z.array(triggerSchema),
});

const durationSchema = z.object({
  duration: z.number(),
  name: z.string(),
});

const burnerSchema = z.object({
  temperature: z.number(),
  name: z.string(),
  isProbe: z.boolean(),
  index: z.number(),
});

const stepSchema = z
  .object({
    description: z.string(),
    descriptionDe: z.string(),
    userInstruction: z.string(),
    userInstructionDe: z.string(),
    preset: z.string().nullable(),
    type: z.string(),
    ordinal: z.number(),
    prepInterval: z.number().nullable(),
    instant: z.boolean(),
    forceStop: z.boolean(),
    method: z.string().nullable(),
    mode: z.string().nullable(),
    durations: z.array(durationSchema),
    notifications: z.array(notificationSchema),
    burners: z.array(burnerSchema),
  })
  .superRefine((data, ctx) => {
    if (data.type !== 'info') {
      if (data.preset === null) {
        ctx.addIssue({
          code: 'invalid_type',
          expected: 'string',
          received: 'null',
          path: ['preset'],
          message: 'preset cannot be null for info step',
        });
      }

      if (data.prepInterval === null) {
        ctx.addIssue({
          code: 'invalid_type',
          expected: 'number',
          received: 'null',
          path: ['prepInterval'],
          message: 'prepInterval cannot be null for info step',
        });
      }
      if (data.method === null) {
        ctx.addIssue({
          code: 'invalid_type',
          expected: 'string',
          received: 'null',
          path: ['method'],
          message: 'method cannot be null for info step',
        });
      }
      if (data.mode === null) {
        ctx.addIssue({
          code: 'invalid_type',
          expected: 'string',
          received: 'null',
          path: ['mode'],
          message: 'mode cannot be null for info step',
        });
      }
    }
  });

const mealSchema = z.object({
  timeOffset: z.number(),
  title: z.string(),
  titleDe: z.string(),
  description: z.string(),
  descriptionDe: z.string().optional(),
  mealType: mealTypeSchema,
  steps: z.array(stepSchema),
});

const groupMetadataSchema = z.object({
  name: z.string(),
  nameDe: z.string(),
  ordinal: z.number(),
  ingredients: z.array(ingredientSchema),
});

const ingredientsSchema = z.object({
  carbs: z.number(),
  protein: z.number(),
  fat: z.number(),
  energy: z.number(),
  maxNumberOfPeople: z.number(),
  groups: z.array(groupMetadataSchema),
});

const complexitySchema = z.object({
  name: z.string(),
  nameDe: z.string(),
});
const categorySchema = z.object({
  name: z.string(),
  nameDe: z.string(),
  description: z.string(),
  descriptionDe: z.string(),
});

const recipeGeneralMetadataRequestSchema = z.object({
  name: z.string(),
  nameDe: z.string(),
  description: z.string(),
  descriptionDe: z.string().optional(),
  cookingTime: z.number(),
  complexity: complexitySchema,
  mealTypes: z.array(mealTypeSchema),
  categories: z.array(categorySchema),
  imageUri: z.string(),
  imageData: z.string().nullable().optional(),
});

const recipeMetadataSchema = z.object({
  general: recipeGeneralMetadataRequestSchema,
  ingredients: ingredientsSchema,
  preparations: z.array(preparationSchema),
  meals: z.array(mealSchema).min(0),
});

export { recipeMetadataSchema };
