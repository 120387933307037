import { zodResolver } from '@hookform/resolvers/zod';
import { Stack } from '@mui/material';
import { useForm } from 'react-hook-form';
import { ComposerFooterButtons, KQNTextInputField } from '../../../../components';
import { CategoryResponse } from '../../../types';
import { updateCategorySchema, UpdateCategorySchemaType } from './schema';

type Props = {
  category?: CategoryResponse;
  onSubmit: (data: UpdateCategorySchemaType) => void;
  isSubmitting?: boolean;
  onClose: () => void;
};

const UpsertCategoryForm: React.FC<Props> = ({ category, onSubmit, isSubmitting, onClose }) => {
  const { control, handleSubmit } = useForm<UpdateCategorySchemaType>({
    resolver: zodResolver(updateCategorySchema),
    defaultValues: {
      name: category?.name || '',
      nameDe: category?.nameDe || '',
      description: category?.description || '',
    },
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack pt={2} gap={2}>
        <Stack
          direction='row'
          justifyContent='space-between'
          gap={2}
          alignContent='center'
          alignItems='center'
        >
          <KQNTextInputField
            name='name'
            label='Category (EN)'
            control={control}
            placeholder='Category (EN)'
          />
          <KQNTextInputField
            name='nameDe'
            label='Category (DE)'
            control={control}
            placeholder='Category (DE)'
          />
        </Stack>
        <KQNTextInputField
          multiline
          fullWidth
          rows={4}
          control={control}
          label='Description (EN)'
          name='description'
          placeholder='Description (EN)'
          InputProps={{
            sx: {
              '& .MuiInputBase-input': {
                px: 2,
                py: 1,
              },
            },
          }}
        />
        <ComposerFooterButtons onClose={onClose} isLoading={isSubmitting} />
      </Stack>
    </form>
  );
};

export default UpsertCategoryForm;
