import { createQueryKeyStore } from '@lukemorales/query-key-factory';
import { koqoonApi } from '../../config';
import { QueryKeys } from '../../constants';
import { PageableQueryFilter } from '../../types';
import { accessoriesService } from '../accessories';
import { categoriesService } from '../categories';
import { dashboardService } from '../dashboard/services';
import { ingredientsService } from '../ingredients';
import { preparationService, recipeService } from '../recipes';
import { groupService } from '../recipes/components/recipe-composer/components/ingredients/components/groups';
import { CategoryResponse, RecipeFilterQuery, RecipeInfinityFilterQuery } from '../types';
import { unitService } from '../units';
import { userService } from '../user';
import {
  burnerService,
  eventService,
  mealStepService,
  mealTypeService,
  nutrientService,
} from './services';

export const queries = createQueryKeyStore({
  users: {
    filter: (filters?: {
      query: string;
      perPage: number;
      page: number;
      orderBy?: string;
      sortBy?: string;
    }) => ({
      queryKey: [QueryKeys.users, { filters }],
      queryFn: () =>
        userService.getUsers(
          filters?.query,
          filters?.page,
          filters?.perPage,
          filters?.sortBy,
          filters?.orderBy,
        ),
    }),
    details: (id?: string) => ({
      queryKey: [QueryKeys.users, id],
      queryFn: async () => {
        const response = await koqoonApi.get(`/users/${id}`);
        return response.data;
      },
    }),
    roles: () => ({
      queryKey: [QueryKeys.roles],
      queryFn: async () => userService.getAllRoles(),
    }),
    activation: (token: string | null, type: string | null, language: string) => ({
      queryKey: [QueryKeys.tokenVerification, { token }],
      queryFn: async () => {
        if (!token || !type) return;
        const path =
          type === 'reset-password' ? '/auth/verify-reset-token' : '/users/token-verification';
        const response = await koqoonApi.post(path, { type, token, language });
        return response.data;
      },
    }),
  },
  recipeCategories: {
    all: () => ({
      queryKey: [QueryKeys.recipeCategory],
      queryFn: () => categoriesService.getCategories({}),
    }),
    byCategory: (categoryId: string) => ({
      queryKey: [QueryKeys.recipesByCategory, categoryId],
      queryFn: () => categoriesService.getCategoryById(categoryId),
    }),
  },
  recipes: {
    details: (id?: string) => ({
      queryKey: [QueryKeys.recipes, id],
      queryFn: () => recipeService.getRecipeById(id),
    }),
    filter: (filters: RecipeFilterQuery) => ({
      queryKey: [QueryKeys.recipes, { filters }],
      queryFn: () =>
        recipeService.getRecipes({
          query: filters.query,
          perPage: filters.perPage,
          page: filters.page,
          cookingStart: filters.cookingStart,
          cookingEnd: filters.cookingEnd,
          complexity: filters.complexity,
          category: filters.category,
          mealType: filters.mealType,
          state: filters.state?.toLowerCase(),
          orderBy: filters.orderBy,
        }),
    }),
    info: (id: string) => ({
      queryKey: [QueryKeys.recipeInfo, id],
      queryFn: () => recipeService.getRecipeInfo(id),
    }),
    infinityFilter: (
      categoryId: string,
      category: CategoryResponse,
      filters: RecipeInfinityFilterQuery,
    ) => ({
      queryKey: [QueryKeys.recipes, { filters, category }],
      queryFn: ({ pageParam }) =>
        recipeService.getRecipesByCategory({
          filterQuery: {
            page: pageParam || 1,
            perPage: 15,
            mealType: filters?.mealType?.join(','),
            cookingStart: filters?.cookingStart,
            cookingEnd: filters?.cookingEnd,
            complexity: filters.complexity?.join(','),
            category: filters.category?.join(','),
            query: filters.query,
            state: filters.state.toLowerCase(),
          },
          categoryId,
        }),
    }),
  },
  meals: {
    filter: (recipeId: string) => ({
      queryKey: [QueryKeys.meals, recipeId],
      queryFn: () => mealStepService.getAllRecipeMealTypeSteps(recipeId),
    }),
  },
  preparations: {
    all: (id?: string) => ({
      queryKey: [QueryKeys.preparations, id],
      queryFn: () => preparationService.getPreparationSteps(id || ''),
    }),
  },
  ingredients: {
    filter: (filters?: {
      query: string;
      perPage: number;
      page: number;
      sortBy?: string;
      orderBy?: string;
    }) => ({
      queryKey: [QueryKeys.ingredients, { filters }],
      queryFn: () =>
        ingredientsService.getIngredients({
          query: filters?.query,
          perPage: filters?.perPage,
          page: filters?.page,
          sortBy: filters?.sortBy,
          orderBy: filters?.orderBy,
        }),
    }),
    details: (id?: string) => ({
      queryKey: [QueryKeys.ingredient, id],
      queryFn: () => ingredientsService.getIngredientById(id),
    }),
  },
  groups: {
    details: (id?: string) => ({
      queryKey: [QueryKeys.ingredients, id],
      queryFn: () => groupService.getRecipeGroups(id),
    }),
  },
  mealTypes: {
    all: () => ({
      queryKey: [QueryKeys.mealTypes],
      queryFn: () => mealTypeService.getAllMealTypes(),
    }),
  },
  categories: {
    filter: (filters?: { query: string; perPage: number; page: number; orderBy?: string }) => ({
      queryKey: [QueryKeys.categories, { filters }],
      queryFn: () =>
        categoriesService.getCategories({
          query: filters?.query,
          perPage: filters?.perPage,
          page: filters?.page,
          orderBy: filters?.orderBy,
        }),
    }),
    details: (id?: string) => ({
      queryKey: [QueryKeys.categories, id],
      queryFn: () => categoriesService.getCategoryById(id),
    }),
  },
  nutrients: {
    filter: (filters?: { query: string; perPage: number; page: number }) => ({
      queryKey: [QueryKeys.nutrients, { filters }],
      queryFn: () => nutrientService.getNutrients(),
    }),
    all: () => ({
      queryKey: [QueryKeys.nutrients],
      queryFn: () => nutrientService.getNutrients(),
    }),
  },
  units: {
    filter: (filters: PageableQueryFilter) => ({
      queryKey: [QueryKeys.units, { filters }],
      queryFn: () => unitService.getUnits(filters),
    }),
    all: () => ({
      queryKey: [QueryKeys.units],
      queryFn: () => unitService.getUnits({ perPage: 1000 }),
    }),
    details: (id?: string) => ({
      queryKey: [QueryKeys.units, id],
      queryFn: () => unitService.findById(id),
    }),
  },
  filters: {
    all: () => ({
      queryKey: [QueryKeys.filters],
      queryFn: () => recipeService.getAvailableFilters(),
    }),
  },
  modes: {
    all: () => ({
      queryKey: [QueryKeys.burnerModes],
      queryFn: () => burnerService.getBurnerModes(),
    }),
  },
  methods: {
    all: () => ({
      queryKey: [QueryKeys.burnerMethods],
      queryFn: () => burnerService.getBurnerMethods(),
    }),
  },
  burners: {
    all: () => ({
      queryKey: [QueryKeys.burners],
      queryFn: () => burnerService.getBurners(),
    }),
  },
  dashboard: {
    all: () => ({
      queryKey: [QueryKeys.dashboard],
      queryFn: () => dashboardService.getAllData(),
    }),
    categories: () => ({
      queryKey: [QueryKeys.dashboardCategories],
      queryFn: () => dashboardService.getPopularCategories(),
    }),
    events: () => ({
      queryKey: [QueryKeys.events],
      queryFn: () => eventService.getAllEvents(),
    }),
  },
  accessories: {
    filter: (filters?: PageableQueryFilter) => ({
      queryKey: [QueryKeys.accessories, { filters }],
      queryFn: () => accessoriesService.getAllData(filters),
    }),
    details: (id?: string) => ({
      queryKey: [QueryKeys.accessories, id],
      queryFn: () => accessoriesService.getAccessoryById(id),
    }),
  },
});
