import { HeadCell } from '../../../../types';
import { RecipeHeadCells } from '../../../types';

const recipesHeadCells: readonly HeadCell<RecipeHeadCells>[] = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Name',
  },
  {
    id: 'categories',
    numeric: false,
    disablePadding: true,
    label: 'Category',
  },
  {
    id: 'complexity',
    numeric: false,
    disablePadding: false,
    label: 'Complexity',
  },
  {
    id: 'isPublished',
    numeric: false,
    disablePadding: true,
    label: 'State',
  },
  {
    id: 'completion',
    numeric: false,
    disablePadding: true,
    label: 'Completion',
  },
];

export default recipesHeadCells;
