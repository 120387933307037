import { Node } from '../chart-types';
import { percentageOfTotalDuration, pixelsOfMaxWidth } from './chart-percentages';

export function calculateTotalDurationTime(nodesArr: Map<string, Node[]>) {
  const firstArr = Array.from(nodesArr.values())
    .map((arr) => arr[0])
    .filter((item) => item !== null && item?.startOffset === 0);
  const firstNode =
    firstArr.length > 0
      ? firstArr.reduce((min, item) =>
          (item?.prepInterval || 0) < (min?.prepInterval || 0) ? item : min,
        )
      : { startOffset: 0, prepInterval: 0 };

  const highestZoneDuration = Math.max(
    ...Array.from(nodesArr.values()).map((arr) => {
      const lastNode = arr[arr.length - 1];
      if (!lastNode) return 0;
      return lastNode.endOffset || 0;
    }),
  );

  const time = pixelsOfMaxWidth(percentageOfTotalDuration(60, highestZoneDuration), 1200);

  /**
   * This is correction for timestamp on the chart.
   * We need to move the timestamp to the right, since we want to display the time starting from the start of first step.
   * So if the first step has a prepInterval and the first meal has start offset, we need to subtract that from the total time.
   * fisrtNode[0]?.startOffset is the start offset of the first step of the first meal. It already contains the start offset of the meal.
   */
  const leftOffsetCorrection = (firstNode?.startOffset || 0) + (firstNode?.prepInterval || 0);
  const timestampDuration = Math.ceil(highestZoneDuration - leftOffsetCorrection);

  const leftOffsetCorrectionWidth = pixelsOfMaxWidth(
    percentageOfTotalDuration(leftOffsetCorrection, highestZoneDuration),
    1200,
  );

  const lastTimeDigit = timestampDuration / 60;

  return {
    highestZoneDuration,
    timestampDuration,
    leftOffsetCorrection,
    time,
    lastTimeDigit,
    leftOffsetCorrectionWidth,
  };
}
