import {
  Box,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Theme,
  useTheme,
} from '@mui/material';
import { durations, useErrorContext } from '../../../../context';

type Props = {
  cookingTime: string[];
  setValue: (event: SelectChangeEvent<any>) => void;
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      borderRadius: '8px',
    },
  },
};

function getStyles(value: string, values: readonly string[], theme: Theme) {
  return {
    fontWeight:
      values.indexOf(value) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const CookingTimeFilter: React.FC<Props> = ({ cookingTime, setValue }) => {
  const theme = useTheme();
  const { error } = useErrorContext();

  return (
    <FormControl
      sx={{
        width: '100%',
        '& .MuiInputLabel-root': {
          color: 'kqn.darkerGray',
        },
      }}
      error={!!error.get('cookingTime-cookingTime')}
    >
      <InputLabel sx={{ color: 'kqn.darkerGray', borderColor: 'kqn.darkerGray' }}>
        Cooking Time
      </InputLabel>
      <Select
        defaultValue={[]}
        multiple
        value={cookingTime}
        onChange={setValue}
        name='cookingTime'
        input={
          <OutlinedInput
            label='Cooking Time'
            sx={{
              color: 'kqn.darkerGray',
              borderRadius: '8px',

              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                color: 'kqn.lightGray',
                borderColor: 'kqn.darkerGray',
              },
            }}
          />
        }
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((value) => (
              <Chip key={value} label={value} />
            ))}
          </Box>
        )}
        MenuProps={MenuProps}
      >
        {durations.map((time) => (
          <MenuItem key={time.value} value={time.value} style={getStyles(time.value, [], theme)}>
            {time.value}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CookingTimeFilter;
