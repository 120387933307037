import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { ConfirmModal, KQNTextField } from '../../../../components';
import { useDebounceValue } from '../../../../shared';
import { useDuplicateRecipe } from '../../mutations';
import { recipeService } from '../../services';

type Props = {
  recipe?: { id: string; name: string };
  isOpen: boolean;
  onClose: (event?: any) => void;
};

const DuplicateRecipeModal: React.FC<Props> = ({ isOpen, onClose, recipe }) => {
  const initialName = `${recipe?.name} - copy`;
  const { handleDuplicateRecipe, isLoading: isDuplicating } = useDuplicateRecipe();
  const [recipeName, setRecipeName] = useState(initialName);
  const debouncedValue = useDebounceValue(recipeName, 500);

  const { data, isFetching, isLoading } = useQuery({
    queryKey: [debouncedValue],
    queryFn: () => recipeService.validateRecipeName(debouncedValue),
    enabled: !!debouncedValue,
  });

  const onConfirm = async (event?: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined) => {
    if (!recipe?.id) return;
    event?.stopPropagation();

    await handleDuplicateRecipe({ recipeId: recipe?.id, recipeName, onSuccess: onClose });
  };

  if (!recipe) return null;

  return (
    <ConfirmModal
      isOpen={isOpen}
      onClose={onClose}
      onCofirm={onConfirm}
      disabled={!recipeName || !data || isFetching || isLoading}
      isProcessing={isDuplicating}
      title='Please enter a name for the new recipe.'
      processTitle='Duplicating recipe..'
    >
      <Box pb={3}>
        <Typography variant='caption' color='kqn.darkerGray'>
          Note: Duplicating <span style={{ fontWeight: 'bold' }}>{recipe.name}</span> will create a
          new recipe with the same data.
        </Typography>
        <KQNTextField
          size='small'
          sx={{ mt: 2 }}
          fullWidth
          label='Recipe name'
          variant='standard'
          value={recipeName}
          onChange={(e) => setRecipeName(e.target.value)}
          error={data === false}
          helperText={data === false ? 'Recipe name already exists.' : ''}
        />
      </Box>
    </ConfirmModal>
  );
};

export default DuplicateRecipeModal;
