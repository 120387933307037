import {
  Box,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Theme,
  useTheme,
} from '@mui/material';
import { useErrorContext } from '../../../../context';

type Props = {
  state: string;
  setValue: (event: SelectChangeEvent<any>) => void;
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      borderRadius: '8px',
    },
  },
};

function getStyles(value: string, values: readonly string[], theme: Theme) {
  return {
    fontWeight:
      values.indexOf(value) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const StateFilter: React.FC<Props> = ({ setValue, state }) => {
  const theme = useTheme();
  const { error } = useErrorContext();

  return (
    <FormControl
      sx={{
        width: '100%',
        '& .MuiInputLabel-root': {
          color: 'kqn.darkerGray',
        },
      }}
      error={!!error.get('cookingTime-cookingTime')}
    >
      <InputLabel sx={{ color: 'kqn.darkerGray', borderColor: 'kqn.darkerGray' }}>
        Recipes State
      </InputLabel>
      <Select
        value={state}
        onChange={setValue}
        name='state'
        input={
          <OutlinedInput
            label='Recipe State'
            sx={{
              color: 'kqn.darkerGray',
              borderRadius: '8px',

              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                color: 'kqn.lightGray',
                borderColor: 'kqn.darkerGray',
              },
            }}
          />
        }
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            <Chip key={selected} label={selected} />
          </Box>
        )}
        MenuProps={MenuProps}
      >
        {[
          { name: 'All', value: '' },
          { name: 'Published', value: 'Published' },
          { name: 'Unpublished', value: 'Unpublished' },
        ].map((st) => (
          <MenuItem key={st.name} value={st.value} style={getStyles(st.name, [state], theme)}>
            {st.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default StateFilter;
