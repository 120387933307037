import { Stack, SxProps, Theme } from '@mui/material';

type Props = {
  value?: any;
  adornment?: string;
  inputProps?: SxProps<Theme>;
  adornmentProps?: SxProps<Theme>;
};

export const InputView = ({ value, adornment, inputProps, adornmentProps }: Props) => {
  return (
    <Stack
      sx={{
        minHeight: '40px',
        minWidth: '120px',
        border: '1px solid #B9B9B9',
      }}
      direction='row'
      alignItems='center'
      justifyContent='start'
    >
      {adornment && (
        <Stack
          alignItems='center'
          justifyContent='center'
          sx={{
            px: 1,
            height: '70%',
            bgcolor: 'inherit',
            color: 'kqn.darkerGray',
            borderRight: '2px solid #AF6B48',
            ...adornmentProps,
          }}
        >
          {adornment}
        </Stack>
      )}
      <Stack
        justifyContent='center'
        alignItems='flex-start'
        sx={{
          overflowX: 'hidden',
          height: '100%',
          width: '100%',
          pl: 1,
          pr: 2,
          ...inputProps,
        }}
      >
        {value}
      </Stack>
    </Stack>
  );
};
