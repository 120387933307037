interface HeaderConfig {
  globalSave: boolean;
  publish: boolean;
  duplicate: boolean;
}

const config: Record<number, HeaderConfig> = {
  0: {
    globalSave: true,
    publish: true,
    duplicate: true,
  },
  1: {
    globalSave: true,
    publish: false,
    duplicate: false,
  },
  2: {
    globalSave: false,
    publish: false,
    duplicate: false,
  },
  3: {
    globalSave: false,
    publish: false,
    duplicate: false,
  },
};

export const useHeaderConfig = (value: number) => {
  return config[value as keyof typeof config];
};
