import { Box, Button, CircularProgress, Stack, Typography } from '@mui/material';
import ModalPopup from '../modal-popup/modal-popup';

type Props = {
  title?: string;
  isProcessing?: boolean;
  isOpen: boolean;
  disabled?: boolean;
  processTitle?: string;
  onClose: (event: any) => void;
  onCofirm: (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  children?: React.ReactNode;
};

const ConfirmModal: React.FC<Props> = ({
  isOpen,
  onClose,
  onCofirm,
  disabled,
  title,
  processTitle,
  isProcessing,
  children,
}) => {
  return (
    <ModalPopup isOpen={isOpen} onClose={onClose}>
      <Box border={0} sx={{ bgcolor: 'background.paper', p: 3, boxShadow: 24 }}>
        {isProcessing ? (
          <Stack direction='column' justifyContent='center' alignItems='center'>
            <Typography component='h1' variant='h5' pb={3}>
              {processTitle || 'Loading..'}
            </Typography>
            <CircularProgress />
          </Stack>
        ) : (
          <>
            <Typography component='h1' variant='h5' pb={3}>
              {title || 'Are you sure you want to proceed?'}
            </Typography>
            {children}
            <Stack alignItems='center' direction='row' gap={3} justifyContent='center'>
              <Button fullWidth data-testid='cancel-button' variant='text' onClick={onClose}>
                Cancel
              </Button>
              <Button
                fullWidth
                data-testid='confirm-button'
                variant='contained'
                disabled={isProcessing || disabled}
                onClick={(e) => onCofirm(e)}
              >
                Confirm
              </Button>
            </Stack>
          </>
        )}
      </Box>
    </ModalPopup>
  );
};

export default ConfirmModal;
