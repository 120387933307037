import { Chip, Stack, Typography } from '@mui/material';
import { RecipeBaseModel } from '../../../types';

type Props = {
  recipe: RecipeBaseModel;
};

const RecipeInfo: React.FC<Props> = ({ recipe }) => {
  const { isPublished, name } = recipe;

  return (
    <Stack direction='row' alignItems='center' justifyContent='space-between'>
      <Stack direction='row' gap={1} alignItems='center'>
        <Typography variant='h5' sx={{ color: 'kqn.darkerGray' }}>
          {name || 'Add Recipe'}
        </Typography>
        {!!recipe.id && (
          <Chip
            label={isPublished ? 'Published' : 'Unpublished'}
            sx={{
              bgcolor: isPublished ? 'kqn.green' : 'kqn.darkGray',
              color: 'kqn.white',
              borderRadius: '8px',
            }}
          />
        )}
      </Stack>
    </Stack>
  );
};

export default RecipeInfo;
