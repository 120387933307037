import { HeadCell } from '../../../types';
import { AccessoryHeadCells } from '../../types';

const accessoriesHeadCells: readonly HeadCell<AccessoryHeadCells>[] = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'EN',
  },
  {
    id: 'nameDe',
    numeric: false,
    disablePadding: true,
    label: 'DE',
  },
  {
    id: 'id',
    numeric: false,
    disablePadding: true,
    label: 'Associated Recipes',
  },
];

export default accessoriesHeadCells;
