import { zodResolver } from '@hookform/resolvers/zod';
import {
  Box,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { ComposerFooterButtons, KQNTextInputField } from '../../../../components';
import { Ingredient, Nutrient, PageableUnit } from '../../../types';
import { IngredientUpdateSchemaType, updateIngredientSchema } from './schema';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

function getStyles(name: string, personName: readonly string[], theme: Theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

type Props = {
  units: PageableUnit[];
  nutrients: Nutrient[];
  ingredient?: Ingredient;
  onSubmit: (data: any) => void;
  onNutrientChange: (nutrient: Nutrient, index: number, value: string) => void;
  onUnitChange: (event: SelectChangeEvent<string[]>) => void;
  selectedUnits: string[];
  onClose: () => void;
  isSubmitting?: boolean;
};

const UpsertIngredientForm: React.FC<Props> = ({
  units,
  onClose,
  onSubmit,
  nutrients,
  ingredient,
  onUnitChange,
  isSubmitting,
  selectedUnits,
  onNutrientChange,
}) => {
  const theme = useTheme();
  const { control, handleSubmit } = useForm<IngredientUpdateSchemaType>({
    resolver: zodResolver(updateIngredientSchema),
    defaultValues: {
      name: ingredient?.name || '',
      nameDe: ingredient?.nameDe || '',
    },
  });

  const handleUpdateNutrientValues = useCallback(
    (
      nutrient: Nutrient,
      identifier: number,
      e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
      const value = Number(e.target.value);
      if (value < 0) return;

      onNutrientChange(nutrient, identifier, e.target.value);
    },
    [onNutrientChange],
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack pt={2} gap={2}>
        <Stack
          direction='row'
          justifyContent='space-between'
          gap={2}
          alignContent='center'
          alignItems='center'
        >
          <KQNTextInputField
            control={control}
            name='name'
            data-testid='ingredients-name-en-input'
            label='Ingredient (EN)'
            placeholder='Ingredient (EN)'
          />
          <KQNTextInputField
            label='Ingredient (DE)'
            placeholder='Ingredient (DE)'
            name='nameDe'
            control={control}
          />
        </Stack>
        <Stack direction={{ xs: 'column', md: 'row' }} gap={2}>
          <FormControl fullWidth>
            <InputLabel id='multiple-chip-label'>Units</InputLabel>
            <Select
              label='Units'
              placeholder='Units'
              defaultValue={[]}
              labelId='multiple-chip-label'
              id='multiple-chip'
              data-testid='ingredients-unit-select'
              multiple
              value={selectedUnits}
              onChange={onUnitChange}
              input={<OutlinedInput id='select-multiple-chip' label='Chip' />}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip
                      key={value}
                      label={value}
                      sx={{ bgcolor: 'kqn.cooper', color: 'kqn.gray' }}
                    />
                  ))}
                </Box>
              )}
              MenuProps={MenuProps}
            >
              {units.map(({ id, name }) => (
                <MenuItem
                  key={id}
                  value={name}
                  style={getStyles(name, [], theme)}
                  data-testid='ingredients-unit-select-item'
                >
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
        <Box flexDirection='column' display='flex' mb={3}>
          <Typography variant='h6' py={2} sx={{ color: 'kqn.darkerGray' }}>
            Nutrition values, per 100g
          </Typography>
          <Stack gap={4} justifyContent='space-between' alignItems='center' m={0} direction='row'>
            {nutrients.map((nutrient, idx) => (
              <Box key={nutrient.id} data-testid='ingredients-nutrients-form'>
                <TextField
                  sx={{ m: 0 }}
                  data-testid={`ingredients-nutrient-${idx}`}
                  label={`${nutrient.name}, ${nutrient.unit.name}`}
                  value={nutrient.amount || ''}
                  onChange={(e) => handleUpdateNutrientValues(nutrient, idx, e)}
                  onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
                  margin='normal'
                  InputProps={{
                    type: 'number',
                    inputMode: 'numeric',
                    inputProps: { style: { textAlign: 'center' } },
                  }}
                />
              </Box>
            ))}
          </Stack>
        </Box>
        <ComposerFooterButtons onClose={onClose} isLoading={isSubmitting} />
      </Stack>
    </form>
  );
};

export default UpsertIngredientForm;
