/* eslint-disable no-param-reassign */
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';
import {
  Burner,
  Duration,
  GrillStep,
  Meal,
  MealTypeModel,
  MethodType,
  ModeType,
  Notification,
} from '../modules/types';
import { storeSelectors } from './create-selectors';

type PresetValues = Pick<
  GrillStep,
  'description' | 'descriptionDe' | 'userInstruction' | 'userInstructionDe'
>;

type Actions = {
  actions: {
    setData: (meals: Array<Meal>) => void;
    setPresets: (mealId: string, presets: Array<GrillStep>) => void;
    addPreset: (mealId: string, preset: GrillStep) => void;
    removePreset: (mealId: string, presetId: string) => void;
    updateInstant: (mealId: string, presetId: string, isInstant: boolean) => void;
    updateForceStop: (mealId: string, presetId: string, forceStop: boolean) => void;
    updatePreset: (mealId: string, presetId: string, values: PresetValues) => void;
    updatePresetValue: (
      mealId: string,
      presetId: string,
      key: keyof PresetValues,
      value: string,
    ) => void;
    updatePresets: (mealId: string, presetId: string, values: GrillStep) => void;
    updateDoneness: (mealId: string, presetId: string, level: string) => void;
    updatePrepInterval: (mealId: string, presetId: string, prepInterval: number) => void;
    lockDurations: (mealId: string, presetId: string, lock: boolean) => void;
    updateBurners: (
      mealId: string,
      presetId: string,
      burners: Array<Burner>,
      type: 'probes' | 'burners',
    ) => void;
    updateDurations: (mealId: string, presetId: string, durations: Array<Duration>) => void;
    updateMealType: (mealId: string, presetId: string, mealType: MealTypeModel) => void;
    updateMode: (mealId: string, presetId: string, mode: ModeType) => void;
    updateMethod: (mealId: string, presetId: string, method: MethodType) => void;
    updateNotifications: (
      mealId: string,
      presetId: string,
      notifications: Array<Notification>,
    ) => void;
    resetState: () => void;
  };
};

type State = { initialData: Array<Meal>; meals: Array<Meal> };

export const useMeals = createWithEqualityFn<Actions & State>()(
  immer(
    devtools((set) => ({
      meals: [],
      initialData: [],
      actions: {
        setData: (meals) => {
          set((state) => {
            state.meals = meals;
            state.initialData = meals;
          });
        },
        updateForceStop: (mealId, presetId, forceStop) => {
          set((state) => {
            const meal = state.meals.find((m) => m.id === mealId);
            if (meal) {
              const step = meal.steps.find((s) => s.id === presetId);
              if (step) {
                step.forceStop = forceStop;
              }
            }
          });
        },
        updateNotifications: (mealId, presetId, notifications) => {
          set((state) => {
            const meal = state.meals.find((m) => m.id === mealId);
            if (meal) {
              const step = meal.steps.find((s) => s.id === presetId);
              if (step) {
                step.notifications = notifications;
              }
            }
          });
        },
        lockDurations: (mealId, presetId, lock) => {
          set((state) => {
            const meal = state.meals.find((m) => m.id === mealId);
            if (meal) {
              const step = meal.steps.find((s) => s.id === presetId);
              if (step) {
                step.lockDurations = lock;
              }
            }
          });
        },
        resetState: () => {
          set((state) => {
            state.meals = [];
            state.initialData = [];
          });
        },
        setPresets: (mealId, steps) => {
          set((state) => {
            const meal = state.meals.find((m) => m.id === mealId);
            if (meal) {
              meal.steps = steps;
            }
          });
        },
        addPreset: (mealId, step) => {
          set((state) => {
            const meal = state.meals.find((m) => m.id === mealId);
            if (meal) {
              meal.steps.push(step);
            }
          });
        },
        removePreset: (mealId, presetId) => {
          set((state) => {
            const meal = state.meals.find((m) => m.id === mealId);
            if (meal) {
              meal.steps = meal.steps.filter((step) => step.id !== presetId);
            }
          });
        },
        updateInstant: (mealId, presetId, isInstant) => {
          set((state) => {
            const meal = state.meals.find((m) => m.id === mealId);
            if (meal) {
              const step = meal.steps.find((s) => s.id === presetId);
              if (step) {
                step.instant = isInstant;
              }
            }
          });
        },
        updatePreset: (mealId, presetId, values) => {
          set((state) => {
            const meal = state.meals.find((m) => m.id === mealId);
            if (meal) {
              const step = meal.steps.find((s) => s.id === presetId);
              if (step) {
                step.description = values.description;
                step.descriptionDe = values.descriptionDe;
                step.userInstruction = values.userInstruction;
                step.userInstructionDe = values.userInstructionDe;
              }
            }
          });
        },
        updatePresetValue: (mealId, presetId, key, value) => {
          set((state) => {
            const meal = state.meals.find((m) => m.id === mealId);
            if (meal) {
              const step = meal.steps.find((s) => s.id === presetId);
              if (step) {
                step[key] = value;
              }
            }
          });
        },
        updatePresets: (mealId, presetId, value) => {
          set((state) => {
            const meal = state.meals.find((m) => m.id === mealId);
            if (meal) {
              const step = meal.steps.find((s) => s.id === presetId);
              if (step) {
                step.id = value.id;
                step.ordinal = value.ordinal;
                step.isDraft = false;
                step.description = value.description;
                step.descriptionDe = value.descriptionDe;
                step.userInstruction = value.userInstruction;
                step.userInstructionDe = value.userInstructionDe;
                step.instant = value.instant;
                step.preset = value.preset;
                step.probes = value.probes;
                step.burners = value.burners;
                step.durations = value.durations;
                step.mealType = value.mealType;
                step.method = value.method;
                step.mode = value.mode;
              }
            }
          });
        },
        updateDoneness: (mealId, presetId, level) => {
          set((state) => {
            const meal = state.meals.find((m) => m.id === mealId);
            if (meal) {
              const step = meal.steps.find((s) => s.id === presetId);
              if (step) {
                step.preset = level;
              }
            }
          });
        },
        updatePrepInterval(mealId, presetId, prepInterval) {
          set((state) => {
            const meal = state.meals.find((m) => m.id === mealId);
            if (meal) {
              const step = meal.steps.find((s) => s.id === presetId);
              if (step) {
                step.prepInterval = prepInterval;
              }
            }
          });
        },
        updateBurners: (mealId, presetId, burners, type) => {
          set((state) => {
            const meal = state.meals.find((m) => m.id === mealId);
            if (meal) {
              const step = meal.steps.find((s) => s.id === presetId);
              if (step) {
                if (type === 'probes') {
                  step.probes = burners;
                } else {
                  step.burners = burners;
                }
              }
            }
          });
        },
        updateDurations(mealId, presetId, durations) {
          set((state) => {
            const meal = state.meals.find((m) => m.id === mealId);
            if (meal) {
              const step = meal.steps.find((s) => s.id === presetId);
              if (step) {
                step.durations = durations;
              }
            }
          });
        },
        updateMealType(mealId, presetId, mealType) {
          set((state) => {
            const meal = state.meals.find((m) => m.id === mealId);
            if (meal) {
              const step = meal.steps.find((s) => s.id === presetId);
              if (step) {
                step.mealType = mealType;
              }
            }
          });
        },
        updateMethod(mealId, presetId, method) {
          set((state) => {
            const meal = state.meals.find((m) => m.id === mealId);
            if (meal) {
              const step = meal.steps.find((s) => s.id === presetId);
              if (step) {
                step.method = method;
              }
            }
          });
        },
        updateMode(mealId, presetId, mode) {
          set((state) => {
            const meal = state.meals.find((m) => m.id === mealId);
            if (meal) {
              const step = meal.steps.find((s) => s.id === presetId);
              if (step) {
                step.mode = mode;
              }
            }
          });
        },
      },
    })),
  ),
);

export const useMealStore = storeSelectors(useMeals);
export const useMealState = () => useMeals((state) => state.meals);
export const useMealInitialState = () => useMeals((state) => state.initialData);
export const usePresets = (mealId: string) =>
  useMeals((state) => state.meals.find((m) => m.id === mealId)?.steps || []);
export const useMealActions = () => useMeals((state) => state.actions, shallow);
