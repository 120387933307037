import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import { Box, Divider, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { ModalPopup } from '../../../../components';
import UpdateUserForm from '../../../../components/update-user-form/update-user-form';
import { PageableUser } from '../../../types';

interface Props {
  user: PageableUser;
  isOpen: boolean;
  onClose: () => void;
}

const UpdateUserModal: React.FC<Props> = ({ user, isOpen, onClose }) => {
  if (!user) return null;

  return (
    <ModalPopup isOpen={isOpen} onClose={onClose}>
      <Box border={0} sx={{ minWidth: '600px', bgcolor: 'background.paper', p: 3, boxShadow: 24 }}>
        <Stack
          gap={2}
          sx={{ color: 'kqn.cooper' }}
          pb={2}
          direction='row'
          alignItems='center'
          justifyContent='flex-start'
        >
          <CreateOutlinedIcon className='icon' />
          <Typography variant='h6'>EDIT USER</Typography>
        </Stack>
        <Divider sx={{ borderColor: 'kqn.lightCooper' }} />
        <UpdateUserForm user={user} onClose={onClose} />
      </Box>
    </ModalPopup>
  );
};

export default UpdateUserModal;
