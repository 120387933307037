import { FormControl, MenuItem, Select, SelectChangeEvent, Stack } from '@mui/material';
import { Title } from '../../../../../../../../../../components';
import { DonenessLevel, ErrorKeys } from '../../../../../../../../../../constants';
import { useErrorContext } from '../../../../../../../../../../context';
import { useMealActions } from '../../../../../../../../../../store/meal-store';
import { Burner, ModeType } from '../../../../../../../../../types';
import { initialDurations, singleDuration } from '../../config';

interface Props {
  mealId: string;
  preset: string;
  stepId: string;
  probes?: Burner[];
  disabled?: boolean;
  method?: ModeType;
}
const PresetTemperature: React.FC<Props> = ({
  mealId,
  method,
  preset,
  stepId,
  probes,
  disabled,
}) => {
  const { error } = useErrorContext();
  const { updateBurners, updateDurations, updateDoneness } = useMealActions();

  const handleSetPreset = (e: SelectChangeEvent<string>) => {
    const name = e.target.value;
    const isSinglePreset = name === DonenessLevel.SINGLE;
    const durationInital = isSinglePreset ? singleDuration : initialDurations;

    updateDoneness(mealId, stepId, name);

    if (!method) return;

    if (method?.name === 'Probe') {
      updateBurners(
        mealId,
        stepId,
        isSinglePreset ? probes?.filter((p) => p.name === 'Default') || [] : probes || [],
        'probes',
      );
      updateDurations(mealId, stepId, singleDuration);
    } else {
      updateBurners(mealId, stepId, [], 'probes');
      updateDurations(mealId, stepId, durationInital);
    }
  };

  return (
    <Stack direction='column' gap={1} sx={{ width: '100%' }}>
      <Title title='Doneness Level' />
      <FormControl fullWidth>
        <Select
          disabled={disabled}
          size='small'
          value={preset ?? ''}
          onChange={handleSetPreset}
          defaultValue=''
          labelId='temperature-preset'
          id='temperature-preset'
          data-testid='temperature-preset'
          error={!!error.get(`${stepId}-${ErrorKeys.PRESET}`)}
        >
          {[
            { presetId: 1, name: 'Single' },
            { presetId: 2, name: 'Multiple' },
          ].map(({ presetId, name }) => (
            <MenuItem key={presetId} value={name} data-testid='temperature-preset-option'>
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Stack>
  );
};
export default PresetTemperature;
