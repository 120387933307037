import { Stack } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { queries } from '../../../queries';
import RecipeCategory from './recipe-category';

const RecipeCardView: React.FC = () => {
  const { data } = useQuery({
    ...queries.recipeCategories.all(),
    refetchOnMount: true,
    refetchOnReconnect: true,
    suspense: true,
  });

  return (
    <Stack gap={2} data-testid='recipes-card-view'>
      {data?.data.map((cat) => (
        <RecipeCategory key={cat.id} category={cat} />
      ))}
    </Stack>
  );
};

export default RecipeCardView;
