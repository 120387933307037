import { Stack, Typography } from '@mui/material';
import React from 'react';

type Props = { label: string; value: string };

const InfoRow: React.FC<Props> = ({ label, value }) => {
  return (
    <Stack direction='row' justifyContent='space-between' alignItems='center'>
      <Stack width='100%'>
        <Typography variant='body2'>{label}</Typography>
      </Stack>
      <Stack width='100%' justifyContent='flex-start'>
        <Typography variant='body2' sx={{ color: 'kqn.darkerGray' }}>
          {value}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default InfoRow;
