import { Box, Stack } from '@mui/material';
import { KQNTextField, MainLayout, TextInputAndormnet, Title } from '../../../../../../components';
import { ErrorKeys } from '../../../../../../constants';
import { useErrorContext } from '../../../../../../context';
import { useRecipeActions, useRecipeStore } from '../../../../../../store';
import { ImageUpload } from '../../../../../images';
import { RecipeInfoFilters } from '../../../recipe-info-filter';
import { GeneralComposerSkeleton } from '../skeletons';

interface Props {
  recipeId: string | null;
  isLoading?: boolean;
}

export const GeneralComposer: React.FC<Props> = ({ recipeId, isLoading }) => {
  const { error } = useErrorContext();
  const recipe = useRecipeStore.use.recipe();
  const { updateRecipeValue, updateComplexity, updateCategory, updateMealType, updateCookingTime } =
    useRecipeActions();

  const {
    name,
    nameDe,
    mealTypes,
    categories,
    complexity,
    imageUri,
    cookingTime,
    description,
    descriptionDe,
  } = recipe;

  if (isLoading) return <GeneralComposerSkeleton />;

  return (
    <MainLayout>
      <Stack direction='row' justifyContent='space-between' gap={3}>
        <Box display='flex' flexDirection='column' gap={2} width='100%' height='100%'>
          <Stack direction='column' gap={1}>
            <Title title='Recipe Name' />
            <KQNTextField
              size='small'
              name='nameEn'
              placeholder='Recipe Name (EN)'
              onChange={(e) => updateRecipeValue('name', e.target.value)}
              value={name}
              required
              error={!!error.get(ErrorKeys.NAME)}
              helperText={error.get(ErrorKeys.NAME)}
              InputProps={{
                sx: {
                  bgcolor: 'kqn.white',
                  minHeight: '40px',
                  '& .MuiInputBase-input': { pr: 1, py: 1 },
                },
                startAdornment: <TextInputAndormnet text='EN' />,
              }}
            />
            <KQNTextField
              size='small'
              name='nameDe'
              placeholder='Recipe Name (DE)'
              onChange={(e) => updateRecipeValue('nameDe', e.target.value)}
              value={nameDe}
              required
              error={!!error.get(ErrorKeys.NAMEDE)}
              helperText={error.get(ErrorKeys.NAMEDE)}
              InputProps={{
                sx: {
                  bgcolor: 'kqn.white',
                  minHeight: '40px',
                  '& .MuiInputBase-input': { pr: 1, py: 1 },
                },
                startAdornment: <TextInputAndormnet text='DE' />,
              }}
            />
          </Stack>
          <Stack direction='column' gap={1}>
            <Title title='Duration, min' />
            <KQNTextField
              size='small'
              sx={{ width: '80px' }}
              name='cookingTime'
              type='number'
              data-testid='cooking-time'
              onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
              onChange={(e) => updateCookingTime(Number(e.target.value))}
              value={cookingTime || ''}
              error={!!error.get(ErrorKeys.COOKING_TIME)}
              helperText={error.get(ErrorKeys.COOKING_TIME)}
              required
            />
          </Stack>
          <RecipeInfoFilters
            complexity={complexity}
            mealTypes={mealTypes}
            categories={categories}
            onUpdateCategories={updateCategory}
            onUpdateComplexity={updateComplexity}
            onUpdateMealTypes={updateMealType}
          />
        </Box>
        <Box width='100%' gap={1} display='flex' flexDirection='column'>
          <Title title='Recipe Image' />
          <ImageUpload
            recipeId={recipeId}
            imageUri={imageUri}
            onUpload={(imgUrl: string) => updateRecipeValue('imageUri', imgUrl)}
          />
        </Box>
      </Stack>
      <Title title='About' />
      <Stack direction='row' mt={1} gap={3}>
        <KQNTextField
          size='small'
          multiline
          fullWidth
          value={description}
          onChange={(e) => updateRecipeValue('description', e.target.value)}
          name='descriptionEn'
          placeholder='About (EN)'
          InputProps={{
            sx: { minHeight: '40px', '& .MuiInputBase-input': { py: 2, px: 1 } },
            startAdornment: <TextInputAndormnet text='EN' />,
          }}
        />
        <KQNTextField
          size='small'
          multiline
          fullWidth
          value={descriptionDe}
          onChange={(e) => updateRecipeValue('descriptionDe', e.target.value)}
          name='descriptionDe'
          placeholder='About (DE)'
          InputProps={{
            sx: { minHeight: '40px', '& .MuiInputBase-input': { py: 2, px: 1 } },
            startAdornment: <TextInputAndormnet text='DE' />,
          }}
        />
      </Stack>
    </MainLayout>
  );
};
