import { RecipeBaseModel, RecipeComplexityModel } from '../../modules/types';

export const recipeInitialState: Omit<RecipeBaseModel, 'accessories'> = {
  id: '',
  name: '',
  nameDe: '',
  cookingTime: 0,
  mealTypes: [],
  complexity: {} as RecipeComplexityModel,
  categories: [],
  file: undefined,
  imageUri: '',
  isPublished: false,
  description: '',
  descriptionDe: '',
  maxNumberOfPeople: 0,
};
